import React from 'react';
import { AutoSuggest } from 'components/Form';
import { Translate } from 'components/Localize';
import geoApi from 'api/GeoApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import style from './CitySelect.module.css';

const CitySelect = ({ companyId, ...props }) => (
  <Translate>
    {({ translate }) => (
      <AutoSuggest
        fetchFunc={value =>
          geoApi
            .getCities(value, 10, companyId)
            .then(items => items.map(item => item.name))
        }
        renderSuggestion={value => (
          <span>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className={style['city-select-icon']}
            />
            {value}
          </span>
        )}
        placeholder={translate('location')}
        {...props}
      />
    )}
  </Translate>
);

export default CitySelect;
