import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MultiSelect } from 'react-selectize';
import { Translate } from 'components/Localize';
import 'react-selectize/themes/index.css';
import { getKindList } from 'actions/kindActions';

import './SelectKind.scss';

const SelectKind = ({ data, onChange, name, getKindList }) => {
  useEffect(() => {
    getKindList();
  }, []);
  const handleChange = value => {
    const sort = data.filter(filter => {
      const foo = value.some(
        item => item.value.toLowerCase() === filter.value.toLowerCase()
      );
      return foo ? value : null;
    });
    onChange({
      target: { name, value: sort.map(({ value }) => value) },
      persist: () => {}
    });
  };
  return (
    <MultiSelect
      className="select-kind"
      placeholder={<Translate id="kind" />}
      transitionEnter={true}
      transitionLeave={true}
      onValuesChange={handleChange}
    >
      {data.map(kind => (
        <option key={kind.value} value={kind.value}>
          {kind.title}
        </option>
      ))}
    </MultiSelect>
  );
};
const mapStateToProps = ({ kind: { data } }) => {
  return {
    data: data || []
  };
};
export default connect(
  mapStateToProps,
  { getKindList }
)(SelectKind);
