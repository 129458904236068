import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactSVG from 'react-svg';
import location from 'styles/img/icons/location.svg';
import { Kind as KindFormat } from 'components/Format';
import hat from 'styles/img/icons/hat.svg';
import style from './CityTagDuration.module.css';

const CityTagDuration = ({ vacancy, className }) => {
  return (
    <ul className={cx(style['city-tag-duration'], className)}>
      <li>
        <ReactSVG
          src={location}
          svgClassName={style['city-tag-duration-icon']}
        />
        {vacancy.address.city.name}
      </li>
      <li>
        <ReactSVG src={hat} svgClassName={style['city-tag-duration-icon']} />
        <KindFormat name={vacancy.kind} />
      </li>
    </ul>
  );
};

CityTagDuration.propTypes = {
  vacancy: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default CityTagDuration;
