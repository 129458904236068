import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import star from 'styles/img/icons/star.svg';
import style from './Rating.module.css';

const renderStars = maxValue => {
  return [...Array(maxValue).keys()].map(index => (
    <ReactSVG key={`rating_${index}`} src={star} />
  ));
};

const Rating = ({ value, maxValue = 5 }) => {
  const percentage = (value / maxValue) * 100;
  return (
    <div className={style['rating']}>
      <div className={style['back-stars']}>
        {renderStars(maxValue)}
        <div
          className={style['front-stars']}
          style={{ width: `${percentage}%` }}
        >
          {renderStars(maxValue)}
        </div>
      </div>
    </div>
  );
};

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number
};

export default Rating;
