import React from 'react';
import Layout from './Layout';
import Routine from './Routine';
import Results from './Results';
import CompanyProvider from 'app/CompanyProvider';
import { BlurProvider } from 'components/BlurEffect';
import PasswordProtection from './PasswordProtection';
import { BrandingStyles } from 'components/Branding';
import TokenReceiver from 'components/TokenReceiver';
const App = () => (
  <BlurProvider>
    <CompanyProvider>
      {({ alias }) => (
        <TokenReceiver>
          <Routine alias={alias}>
            <BrandingStyles>
              <PasswordProtection>
                <Layout>
                  <Results />
                </Layout>
              </PasswordProtection>
            </BrandingStyles>
          </Routine>
        </TokenReceiver>
      )}
    </CompanyProvider>
  </BlurProvider>
);

export default App;
