import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setDefaultLanguage } from 'lib/languages';
import { refetchKindList } from 'actions/kindActions';
import { refetchTagList } from 'actions/tagActions';
import { refetchCompany } from 'actions/companyActions';
import { refetchAssistantList } from 'actions/assistantAction';
import { refetchApplicationStatuses } from 'actions/applicationStatusesActions';
import { CompanyResolver } from 'app/CompanyProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'components/Tooltip';
import styles from './LanguageSwitcher.module.css';
import cx from 'classnames';

class LanguageSwitcher extends React.Component {
  state = {
    open: false
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleHide = () => {
    this.setState({ open: false });
  };

  handleClickFor = (code, alias) => () => {
    setDefaultLanguage(code);
    this.props.setActiveLanguage(code);
    this.props.refetchAssistantList();
    this.props.refetchKindList(alias);
    this.props.refetchTagList(alias);
    this.props.refetchCompany(alias);
    this.props.refetchApplicationStatuses();
    this.handleHide();
  };

  render() {
    const { languages, activeLanguage } = this.props;
    const { open } = this.state;
    return (
      <CompanyResolver>
        {({ alias }) => (
          <Tooltip
            on="manual"
            placement="bottom-end"
            open={open}
            onOpen={this.handleOpen}
            onHide={this.handleHide}
            theme="light"
            message={
              <ul className={styles['language-switcher__menu']}>
                {languages.map(lang => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    key={lang.code}
                    onClick={this.handleClickFor(lang.code, alias)}
                  >
                    {lang.name}
                  </li>
                ))}
              </ul>
            }
          >
            <div className={styles['language-switcher']}>
              <span>{activeLanguage.name}</span>
              <span className={cx(styles.icon, { [styles.icon__open]: open })}>
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </div>
          </Tooltip>
        )}
      </CompanyResolver>
    );
  }
}

export default compose(
  withLocalize,
  connect(
    undefined,
    {
      refetchKindList,
      refetchTagList,
      refetchCompany,
      refetchApplicationStatuses,
      refetchAssistantList
    }
  )
)(LanguageSwitcher);
