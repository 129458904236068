import React from 'react';
import PropTypes from 'prop-types';
import Branding from 'components/Branding';
import ApplyButton from '../ApplyButton';
import Applied from '../Applied';
import CityTagDuration from '../CityTagDuration';
import KindCompany from '../KindCompany';
import Title from '../Title';
import style from './Standard.module.css';

const Standard = ({ vacancy }) => {
  return (
    <Branding>
      {resolve => (
        <div className={style['vacancy-header']}>
          {resolve('apply.vacancy_list.show_logo', true) && (
            <div className={style['vacancy-header-logo']}>
              <img
                src={
                  vacancy.company_logo
                    ? vacancy.company_logo
                    : vacancy.company.logo
                }
                alt="logo"
              />
            </div>
          )}
          <div className={style['vacancy-header-content']}>
            {/*<Kind vacancy={vacancy} className={style['phone-visible']} />*/}
            <Title vacancy={vacancy} />
            {/*<Company vacancy={vacancy}  />*/}
            <KindCompany vacancy={vacancy} />
            <CityTagDuration vacancy={vacancy} />
          </div>
          <div className={style['apply-wrapper']}>
            <Applied vacancy={vacancy}>
              <ApplyButton
                vacancyId={vacancy.pk}
                vacancyCompanyId={vacancy.company.pk}
                externalLink={vacancy.external_link}
              />
            </Applied>
          </div>
        </div>
      )}
    </Branding>
  );
};

Standard.propTypes = {
  vacancy: PropTypes.object.isRequired
};

export default Standard;
