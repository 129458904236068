import React from 'react';
import { Translate } from 'components/Localize';
import { getDefaultLanguage } from 'lib/languages';
import Select from '../Select';
import { format, parse } from './dateFormat';
import style from './Date.module.css';

function* range(start, end) {
  while (start <= end) {
    yield start;
    // eslint-disable-next-line no-param-reassign
    start += 1;
  }
}

const language = getDefaultLanguage();
const days = Array.from(range(1, 31)).map(item => ({
  value: item,
  label: item
}));
const months = shortMonth =>
  Array.from(range(0, 11)).map(month => ({
    value: month + 1,
    label: new Date(2000, month).toLocaleString(language, {
      month: shortMonth ? 'short' : 'long'
    })
  }));
const currentYear = new Date().getFullYear();
const years = older16 =>
  Array.from(range(currentYear - 100, currentYear - (older16 ? 16 : 0)))
    .reverse()
    .map(item => ({
      value: item,
      label: item
    }));

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    const toState = ({ day, month, year }) => ({
      day: days.find(({ value }) => value === day),
      month: months(props.shortMonth).find(({ value }) => value === month),
      year: years().find(({ value }) => value === year)
    });

    if (props.value) {
      this.state = toState(parse(props.value));
    } else {
      this.state = toState({
        day: props.withoutDay ? 1 : undefined,
        month: undefined,
        year: undefined
      });
    }
  }

  handleChangeFor = name => value => {
    const { props } = this;
    props.meta.setTouched({ [props.name]: true });
    this.setState(
      {
        [name]: value
      },
      () => {
        const { day, month, year } = this.state;
        if (day && month && year) {
          props.meta.setFieldValue(
            props.name,
            format({ year: year.value, month: month.value, day: day.value })
          );
        }
      }
    );
  };

  render() {
    const { day, month, year } = this.state;
    const { label, older16, withoutDay, invalid, shortMonth } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <div className={style['date-input']}>
            <label>{label}</label>
            {!withoutDay && (
              <Select
                name="day"
                value={day}
                invalid={invalid}
                className={style['date-select']}
                onChange={this.handleChangeFor('day')}
                placeholder={translate('day')}
                options={days}
              />
            )}
            <Select
              name="month"
              value={month}
              invalid={invalid}
              className={style['date-select']}
              onChange={this.handleChangeFor('month')}
              placeholder={translate('month')}
              options={months(shortMonth)}
            />
            <Select
              name="year"
              value={year}
              invalid={invalid}
              className={style['date-select']}
              onChange={this.handleChangeFor('year')}
              placeholder={translate('year')}
              options={years(older16)}
            />
          </div>
        )}
      </Translate>
    );
  }
}

export default DatePicker;
