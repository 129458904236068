import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';

const Carousel = React.forwardRef(
  (
    {
      children,
      dots,
      variableWidth,
      asNavFor,
      initialSlide = 0,
      arrows = true,
      beforeChange
    },
    ref
  ) => {
    return (
      <Slider
        dots={dots}
        initialSlide={initialSlide}
        asNavFor={asNavFor}
        variableWidth={variableWidth}
        infinite={false}
        swipeToSlide
        adaptiveHeight
        ref={ref}
        arrows={arrows}
        beforeChange={beforeChange}
      >
        {children}
      </Slider>
    );
  }
);

Carousel.propTypes = {
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  variableWidth: PropTypes.bool,
  initialSlide: PropTypes.number,
  asNavFor: PropTypes.object,
  afterChange: PropTypes.func
};

export default Carousel;
