import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components/Localize';
import { Kind } from 'components/Format';
import Markdown from 'components/Markdown';
import style from './Details.module.css';

const Description = ({ vacancy }) => {
  return (
    <div className={style['vacancy-body-block']}>
      <h3 className={style['vacancy-body-block-title']}>
        <Translate
          id="description-title"
          data={{
            kind: <Kind name={vacancy.kind} />,
            company: vacancy.company.name
          }}
        />
      </h3>
      <Markdown>{vacancy.description}</Markdown>
    </div>
  );
};

Description.propTypes = {
  vacancy: PropTypes.object.isRequired
};

export default Description;
