import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Info.css';

const Info = ({ children }) => {
  return (
    <div className="alert-info">
      <div>
        <FontAwesomeIcon icon={faInfoCircle} className="alert-info-icon" />
      </div>
      {children}
    </div>
  );
};

export default Info;
