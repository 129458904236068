import * as types from './types';
import accessTokenStorage from 'lib/accessTokenStorage';
import authApi from 'api/AuthApi';
import userApi from 'api/UserApi';

export function login(email, password) {
  return dispatch => {
    return authApi.login(email, password).then(data => {
      accessTokenStorage.set(data.key);
      return dispatch(getAuthUser());
    });
  };
}

export function googleLogin(accessToken) {
  return dispatch => {
    return authApi.googleLogin(accessToken).then(data => {
      accessTokenStorage.set(data.key);
      return dispatch(setCurrentUser(data.ufouser));
    });
  };
}

export function facebookLogin(accessToken) {
  return dispatch => {
    return authApi.facebookLogin(accessToken).then(data => {
      accessTokenStorage.set(data.key);
      return dispatch(setCurrentUser(data.ufouser));
    });
  };
}

export function getAuthUser() {
  return dispatch => {
    if (accessTokenStorage.isExist()) {
      return authApi
        .getAuthUser()
        .then(data => dispatch(setCurrentUser(data.ufouser)));
    } else {
      return Promise.resolve();
    }
  };
}

export function setCurrentUser(user) {
  return { type: types.SET_CURRENT_USER, user };
}

export function logout() {
  return dispatch => {
    return authApi.logout().then(data => {
      accessTokenStorage.remove();
      dispatch({ type: types.LOGOUT });
    });
  };
}

export function register(user) {
  return dispatch => {
    return authApi.register(user).then(data => {
      accessTokenStorage.set(data.token);
      return data.ufouser;
    });
  };
}
export function callBack(first_name, phone_number) {
  return dispatch => {
    return authApi.callBack(first_name, phone_number).then(data => {
      console.log(data);

      // return dispatch(getAuthUser());
    });
  };
}
export function verify(phone) {
  return dispatch => {
    return authApi.verify(phone);
  };
}

export function validate(phone, code) {
  return dispatch => {
    return authApi.validate(phone, code).then(() => {
      return dispatch(getAuthUser());
    });
  };
}

export function updatePhoneSilently(userId, phone) {
  return () => {
    //operation during registration.
    //do not change the store, because after verification, the user data loads anyway.
    return userApi.updatePhone(userId, phone);
  };
}
