import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'components/Carousel';
import MarketTooltip from './MarketTooltip';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'components/Localize';
import style from './Map.module.css';

const MarkerMultipleTooltip = ({ items, resolve }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const carousel = React.createRef();
  return (
    <div className={style['marker-multiple-tooltip']}>
      <div className={style['marker-multiple-tooltip-nav']}>
        <button
          onClick={() => carousel.current.slickPrev()}
          className={style['marker-multiple-tooltip-nav-btn']}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className={style['marker-multiple-tooltip-nav-label']}>
          <Translate
            id="job-current-of-quantity"
            data={{ current: activeSlide + 1, quantity: items.length }}
          />
        </div>
        <button
          onClick={() => carousel.current.slickNext()}
          className={style['marker-multiple-tooltip-nav-btn']}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <Carousel
        variableWidth
        arrows={false}
        ref={carousel}
        beforeChange={(current, next) => setActiveSlide(next)}
      >
        {items.map(item => (
          <MarketTooltip
            key={item.pk}
            logo={item.companylogo}
            title={item.vacancy_title}
            href={resolve(`/s/vacancy/${item.pk}`)}
            company={item.company_name}
            kind={item.kind}
          />
        ))}
      </Carousel>
    </div>
  );
};

MarkerMultipleTooltip.propTypes = {
  items: PropTypes.array.isRequired,
  resolve: PropTypes.func.isRequired
};

export default MarkerMultipleTooltip;
