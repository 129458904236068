import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Dialog.css';

const Dialog = ({ children, className }) => {
  return <div className={cx('dialog', className)}>{children}</div>;
};

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Dialog;
