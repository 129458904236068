import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Translate } from 'components/Localize';
import Branding from 'components/Branding';
import { connect } from 'react-redux';
import applyApi from 'api/ApplyApi';
import ReactSVG from 'react-svg';
import { CompanyResolver } from 'app/CompanyProvider';
import arrows from 'styles/img/icons/arrows.svg';
import style from './ApplyButton.module.css';

const ApplyButton = ({
  brandCompanyId,
  vacancyCompanyId,
  keepCompaniesBranding,
  vacancyId,
  externalLink
}) => {
  let companyId = brandCompanyId;
  //if branding.keep_companies_branding IS TRUE
  //OR if branding.keep_companies_branding IS null
  //OR page without branding (e.g. videobewerbung.de/apply)
  if (keepCompaniesBranding === null || keepCompaniesBranding === true) {
    companyId = vacancyCompanyId;
  }
  return (
    <Branding>
      {resolve => (
        <CompanyResolver>
          {({ alias }) => (
            //apply_button class for searching elements in DOM (by SEO)
            <a
              className={cx(
                'button button--full apply_button',
                style['apply-btn']
              )}
              onClick={e => {
                e.stopPropagation();
              }}
              //UFO-251: if path contain 'slug' and `external_link` is not null => use external_link.
              href={
                alias && externalLink
                  ? externalLink
                  : applyApi.getApplyLink(companyId, vacancyId)
              }
              target={
                resolve('apply.vacancy_list.open_vacancy_in_new_tab', false)
                  ? '_blank'
                  : '_self'
              }
            >
              <Translate id="apply" />
              <ReactSVG src={arrows} svgClassName={style['apply-btn-icon']} />
            </a>
          )}
        </CompanyResolver>
      )}
    </Branding>
  );
};

ApplyButton.propTypes = {
  vacancyId: PropTypes.number.isRequired,
  vacancyCompanyId: PropTypes.number.isRequired,
  externalLink: PropTypes.string
};

const mapStateToProps = ({ company }) => ({
  brandCompanyId:
    company.data && company.data.company && company.data.company.pk,
  keepCompaniesBranding:
    company.data && company.data.branding.keep_companies_branding
});

export default connect(mapStateToProps)(ApplyButton);
