import * as types from './types';
import kindApi from 'api/KindApi';
import { createAction } from 'lib/callAPI';

export function getKindList(companyId, partner) {
  return createAction({
    type: types.GET_KIND_LIST,
    callAPI: () => kindApi.getKindList(companyId, partner),
    shouldCallAPI: state => !state.kind.data && !state.kind.loading
  });
}

export function refetchKindList(companyId, partner) {
  return createAction({
    type: types.GET_KIND_LIST,
    callAPI: () => kindApi.getKindList(companyId, partner)
  });
}
