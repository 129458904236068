import * as types from 'actions/types';
import { createReducer, successType } from 'lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    // after removing there request to get previous
    case successType(types.GET_VIDEO):
      if (action.response && action.response.length > 0) {
        return { ...action.response[0] };
      }
      return null;
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer([types.GET_VIDEO], dataReducer);
