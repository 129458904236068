import * as types from './types';
import authApi from 'api/AuthApi';
import { createAction } from 'lib/callAPI';
import passwordTokenStorage from 'lib/passwordTokenStorage';

export function submitPassword(password, company) {
  return createAction({
    type: types.SUBMIT_PASSWORD,
    callAPI: () =>
      authApi.submitPassword(password, company).then(data => {
        passwordTokenStorage.set(data.key);
        return data;
      })
  });
}

export function validatePasswordToken(company) {
  return createAction({
    type: types.VALIDATE_PASSWORD_TOKEN,
    callAPI: () =>
      authApi.validatePasswordToken(passwordTokenStorage.get(), company),
    shouldCallAPI: () => passwordTokenStorage.isExist()
  });
}
