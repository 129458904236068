import React from 'react';
import PropTypes from 'prop-types';
import { getTagList } from 'actions/tagActions';
import { connect } from 'react-redux';

class Tag extends React.Component {
  componentDidMount() {
    this.props.getTagList();
  }

  render() {
    const { slug, loading, error, data } = this.props;
    if (loading || error || !data) {
      return null;
    }
    const field = data.find(field => field.slug === slug);
    if (!field) {
      return slug;
    }
    return field.name;
  }
}

Tag.propTypes = {
  slug: PropTypes.string
};

const mapStateToProps = ({ tag: { data, error, loading } }) => ({
  loading: loading,
  error: error,
  data: data
});

export default connect(
  mapStateToProps,
  { getTagList }
)(Tag);
