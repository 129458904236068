import React from 'react';
import PropTypes from 'prop-types';
import { Translate as Localize } from 'react-localize-redux';

const Translate = props => {
  return <Localize {...props} />;
};

Translate.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  options: PropTypes.object
};

export default Translate;
