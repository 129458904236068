import React, { useEffect } from 'react';
import { trackVirtualPageView } from 'lib/googleTagManager';
import Branding from 'components/Branding';

const withPageTrack = (WrappedComponent, pageTitle) => {
  return props => {
    return (
      <Branding>
        {resolve => {
          const isTrackingEnabled = resolve('enable_tracking');
          if (
            process.env.NODE_ENV === 'production' &&
            isTrackingEnabled !== false
          ) {
            useEffect(() => trackVirtualPageView(pageTitle), [
              props.location.pathname
            ]);
          }
          return <WrappedComponent {...props} />;
        }}
      </Branding>
    );
  };
};

export default withPageTrack;
