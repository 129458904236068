import 'lib/polyfill';
import React from 'react';
import { render } from 'react-dom';
import App from './app/App';
import configureStore from 'store/configureStore';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import { BrowserRouter } from 'react-router-dom';
import register from './registerServiceWorker';
import { init as initGoogleTagManager } from 'lib/googleTagManager';
import { init as initFacebookTacking } from 'lib/facebookTacking';
import { init as sentryErrorTracking } from 'lib/sentryErrorTracking';
import { AdBlockerDetector } from 'app/AdBlockerDetector/AdBlockerDetector';
import 'lib/cssVariables';
import './styles/scss/index.css';

const store = configureStore();

if (process.env.NODE_ENV === 'production') {
  sentryErrorTracking();
  initGoogleTagManager();
  initFacebookTacking();
}

render(
  <Provider store={store}>
    <LocalizeProvider>
      <BrowserRouter basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <AdBlockerDetector>
          <App />
        </AdBlockerDetector>
      </BrowserRouter>
    </LocalizeProvider>
  </Provider>,
  document.getElementById('root')
);

register();
