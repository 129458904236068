import React from 'react';
import PropTypes from 'prop-types';
import style from './TopJob.module.css';
import { Rating } from 'components/Format';
import { Translate } from 'components/Localize';
import ApplyButton from '../ApplyButton';
import Applied from '../Applied';
import CityTagDuration from '../CityTagDuration';
import KindCompany from '../KindCompany';
import Title from '../Title';

const TopJobHeader = ({ vacancy }) => {
  return (
    <div className={style['top-job-header']}>
      {vacancy.image_list.length > 0 && (
        <div
          className={style['top-job-image']}
          style={{ backgroundImage: `url('${vacancy.image_list[0].image}')` }}
        >
          <img
            src={
              vacancy.company_logo ? vacancy.company_logo : vacancy.company.logo
            }
            alt="logo"
          />
        </div>
      )}
      <div className={style['top-job-body']}>
        <div className={style['top-job']}>
          <Translate id="top-job" />{' '}
          <Rating value={parseFloat(vacancy.company.rating)} />
        </div>
        <KindCompany vacancy={vacancy} />
        <Title vacancy={vacancy} />
        <CityTagDuration vacancy={vacancy} />
        <div className={style['top-job-apply-wrapper']}>
          <Applied vacancy={vacancy}>
            <ApplyButton
              vacancyId={vacancy.pk}
              vacancyCompanyId={vacancy.company.pk}
              externalLink={vacancy.external_link}
            />
          </Applied>
        </div>
      </div>
    </div>
  );
};

TopJobHeader.propTypes = {
  vacancy: PropTypes.object.isRequired
};

export default TopJobHeader;
