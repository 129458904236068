import React from 'react';
import Media from 'react-media';

const MediaQuery = ({ children, query }) => {
  return <Media query={query}>{children}</Media>;
};

const SmallMobile = ({ children }) => {
  return (
    <MediaQuery query="(min-width: 0) and (max-width: 480px)">
      {children}
    </MediaQuery>
  );
};

const Mobile = ({ children }) => {
  return (
    <MediaQuery query="(min-width: 0) and (max-width: 768px)">
      {children}
    </MediaQuery>
  );
};

export { SmallMobile };
export { Mobile };
