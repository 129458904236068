import React from 'react';
import PropTypes from 'prop-types';
import DropDown from 'components/DropDown';
import Loader from 'components/Loader';
import { Error } from 'components/Alert';
import Checkbox from 'components/Checkbox';
import { getKindList } from 'actions/kindActions';
import { connect } from 'react-redux';
import style from './KindDropDown.module.css';
import { Translate } from 'components/Localize';

class KindDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedTags: props.value
    };
  }

  componentDidMount() {
    this.props.getKindList(this.props.companyId, this.props.partner);
  }

  componentDidUpdate(prevProps) {
    //compare array
    if (this.props.value.join('') !== prevProps.value.join('')) {
      this.setState({
        checkedTags: this.props.value
      });
    }
  }

  handleChangeFor = name => e => {
    const { checkedTags } = this.state;
    let newState;
    if (e.target.checked) {
      newState = [...checkedTags, name];
    } else {
      newState = checkedTags.filter(kind => kind !== name);
    }
    this.setState({
      checkedTags: newState
    });
  };

  handleClear = () => {
    this.setState({
      checkedTags: []
    });
  };
  handleApply = () => {
    const { checkedTags } = this.state;
    const { onChange, name } = this.props;

    onChange({
      target: { name, value: checkedTags },
      persist: () => {}
    });
  };

  renderContent() {
    const { loading, data, error } = this.props;
    const { checkedTags } = this.state;
    if (error) {
      return (
        <Error
          general
          retry={() => this.props.getKindList(this.props.companyId)}
        />
      );
    }
    if (loading) {
      return <Loader />;
    }
    return (
      <div className={style['kind-dropdown-content']}>
        {data.map(kind => (
          <Checkbox
            key={`kind_checkbox${kind.value}`}
            value={checkedTags.includes(kind.value)}
            onChange={this.handleChangeFor(kind.value)}
          >
            {kind.title}
          </Checkbox>
        ))}
      </div>
    );
  }

  getHeaderText() {
    const { checkedTags } = this.state;
    const count = checkedTags.length;
    const isAllChecked = checkedTags.length === 0;
    return (
      <span>
        <Translate id="kind" />
        {count > 0 && !isAllChecked && ` · ${count}`}
      </span>
    );
  }

  render() {
    const { checkedTags } = this.state;
    const { data, className } = this.props;
    const someChecked = checkedTags.length !== data.length;
    const allChecked = checkedTags.length === 0;

    return (
      <DropDown
        className={className}
        containerClassName={style['kind-dropdown-container']}
        header={this.getHeaderText()}
        onClear={this.handleClear}
        onApply={this.handleApply}
        showClear={someChecked}
        active={!allChecked}
      >
        {this.renderContent()}
      </DropDown>
    );
  }
}

KindDropDown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = ({ company, kind: { data, error, loading } }) => ({
  companyId: company.data && company.data.company && company.data.company.pk,
  partner: company.data && company.data.partner,
  loading: !data || loading,
  error: error,
  data: data || []
});

export default connect(
  mapStateToProps,
  { getKindList }
)(KindDropDown);
