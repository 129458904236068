import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Translate } from 'components/Localize';
import 'libphonenumber-js-utils';

const validateFor = (schema, translate) => values => {
  const errors = {};
  Object.keys(schema || {}).forEach(field => {
    if (!values[field]) {
      let required = schema[field].required;
      if (typeof required === 'function') {
        required = required(values);
      }
      if (required) {
        errors[field] = translate('this-field-is-required');
      }
    } else {
      if (
        schema[field].email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field])
      ) {
        errors[field] = translate('invalid-email-address');
      }
      if (schema[field].same && values[field] !== values[schema[field].same]) {
        errors[field] = translate('passwords-must-match');
      }
      if (
        schema[field].minLength &&
        values[field].length < schema[field].minLength
      ) {
        errors[field] = translate('field-should-have-least-n-characters', {
          n: schema[field].minLength
        });
      }
      if (schema[field].phone) {
        if (!/^\+?\d+$/.test(values[field])) {
          errors[field] = translate('only-digits-are-allowed');
        } else {
          if (!window.intlTelInputUtils.isValidNumber(values[field])) {
            errors[field] = translate(
              'special-letters-are-not-allowed-telephone-number'
            );
          }
        }
      }
    }
  });
  return errors;
};

const handleSubmitFor = (onSubmitCustom, translate) => (values, formik) => {
  return onSubmitCustom(values, formik)
    .then(() => {
      formik.setSubmitting(false);
    })
    .catch(error => {
      console.error(error);
      formik.setSubmitting(false);
      const errors = {};
      if (error.message) {
        errors._error =
          Object.prototype.toString.call(error.message) === '[object Array]'
            ? error.message[0]
            : error.message;
      } else {
        errors._error = translate('request-failed');
      }
      formik.setErrors(errors);
    });
};

const Form = ({
  validateSchema,
  onSubmitCustom,
  onSubmit,
  children,
  ...props
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <Formik
          validate={validateFor(validateSchema, translate)}
          onSubmit={
            onSubmitCustom
              ? handleSubmitFor(onSubmitCustom, translate)
              : onSubmit
          }
          {...props}
        >
          {children}
        </Formik>
      )}
    </Translate>
  );
};

Form.propTypes = {
  validateSchema: PropTypes.object //{field: {required: true, email: true, same: 'field_name', minLength: 12}}
};

export default Form;
