import ReactPixel from 'react-facebook-pixel';

const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

export const init = () => {
  //initialize only of env variable exist.
  if (facebookPixelId) {
    ReactPixel.init(facebookPixelId);
    ReactPixel.pageView();
  }
};

export const trackRegistration = () => {
  //track only of env variable exist.
  if (facebookPixelId) {
    ReactPixel.track('CompleteRegistration');
  }
};
