import React from 'react';
import Branding from './Branding';
import { Helmet } from 'react-helmet';
import { update as updateCssVars } from 'lib/cssVariables';

const BrandingStyles = ({ children }) => {
  return (
    <Branding>
      {resolve => {
        updateCssVars({
          '--body-border-color': resolve('body.border_color'),
          '--body-primary-color': resolve('body.primary_color'),
          '--body-secondary-color': resolve('body.secondary_color'),
          '--button-primary-text-color': resolve('buttons.primary.text_color'),
          '--button-primary-bg-color': resolve(
            'buttons.primary.background_color'
          ),
          '--button-secondary-text-color': resolve(
            'buttons.secondary.background_color'
          ),
          '--button-secondary-bg-color': resolve(
            'buttons.secondary.background_color'
          ),
          '--header-text-color': resolve('apply.header.text_color'),
          '--header-bg-color': resolve('apply.header.background_color'),
          '--header-bg-image':
            resolve('apply.header.background_image') &&
            `url(${resolve('apply.header.background_image')})`
        });

        const faviconDefaultUrl = `${process.env.PUBLIC_URL}/favicon.png`;
        const titleDefault = 'JobUFO';
        const descriptionDefault = 'JobUFO';
        // handle property not defined and null
        const faviconUrl =
          resolve('favicon', faviconDefaultUrl) || faviconDefaultUrl;
        const title = resolve('meta_title', titleDefault) || titleDefault;
        const description =
          resolve('meta_description', descriptionDefault) || descriptionDefault;
        return (
          <React.Fragment>
            <Helmet>
              {resolve('extra_stylesheet') && (
                <link rel="stylesheet" href={resolve('extra_stylesheet')} />
              )}
              <link rel="shortcut icon" href={faviconUrl} />
              <title>{title}</title>
              <meta name="description" content={description} />
            </Helmet>
            {children}
          </React.Fragment>
        );
      }}
    </Branding>
  );
};
export default BrandingStyles;
