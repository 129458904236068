import React from 'react';
import Search from 'app/Filter/Search';
import { HeaderCity } from 'app/Filter/City';
import InlineFilter from './InlineFilter';
import Common from '../Common';
import search from 'styles/img/icons/search-gradient.svg';
import location from 'styles/img/icons/placement.svg';
import './Narrow.css';

const Narrow = () => {
  const header = <Search isIconPath={search} />;
  const citySearch = <HeaderCity isIconPath={location} />;
  return (
    <React.Fragment>
      <header className="header-narrow">
        <Common header={header} citySearch={citySearch} noPadding />
        {/*/!*<div className="container">*!/*/}
        {/*/!*<SmallMobile>*!/*/}
        {/*/!*<Search isIconPath={search} />*!/*/}
        {/*/!*</SmallMobile>*!/*/}
        {/*/!*</div>*!/*/}
        {/*</Common>*/}
      </header>
      <InlineFilter />
    </React.Fragment>
  );
};

export default Narrow;
