const landingUrl = process.env.REACT_APP_LANDING_URL;

export const getMainUrl = language => {
  return `${landingUrl}/${language}/`;
};
export const getLogoutUrl = language =>
  `${getMainUrl(language)}profile/logout/`;

export const getLoginUrl = language => `${getMainUrl(language)}accounts/login/`;

export const getSignUpUrl = language =>
  `${getMainUrl(language)}accounts/signup/`;

export const getTermsUrl = language => {
  return `${getMainUrl(language)}legal/#terms-of-use`;
};

export const getLegalUrl = language => {
  return `${getMainUrl(language)}legal/`;
};
