import React from 'react';
import localizationApi from 'api/LocalizationApi';
import { renderToStaticMarkup } from 'react-dom/server';
import { getCompany } from 'actions/companyActions';
import { getAuthUser } from 'actions/authActions';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getDefaultLanguage, languages } from 'lib/languages';
import accessTokenStorage from 'lib/accessTokenStorage';

class Routine extends React.Component {
  state = { loading: true, error: false };

  componentDidMount() {
    Promise.all([
      this.getLocalization(),
      this.getBranding(),
      this.authenticate()
    ]).then(() => {
      this.setState({ loading: false });
    });
  }

  authenticate() {
    return this.props.getAuthUser().catch(({ status }) => {
      if (status === 403 || status === 401) {
        //if access token is not valid, remove it and load again.
        accessTokenStorage.remove();
        return this.authenticate();
      }
    });
  }

  getBranding() {
    const { getCompany, alias } = this.props;

    if (!alias) {
      return Promise.resolve();
    }

    return getCompany(alias).then(({ error }) => {
      if (error && (error.status === 403 || error.status === 401)) {
        //if access token is not valid, remove it and load again.
        accessTokenStorage.remove();
        return this.getBranding();
      }
    });
  }

  getLocalization() {
    const { alias } = this.props;
    return localizationApi
      .getLocalization(alias)
      .then(json => {
        const defaultLanguage = getDefaultLanguage();
        this.props.initialize({
          languages,
          translation: json,
          options: {
            defaultLanguage,
            renderToStaticMarkup
          }
        });
      })
      .catch(({ status }) => {
        if (status === 403 || status === 401) {
          //if access token is not valid, remove it and load again.
          accessTokenStorage.remove();
          return this.getLocalization();
        } else {
          this.setState({ error: true });
        }
      });
  }

  render() {
    const { loading, error } = this.state;
    if (error) {
      return <div>Failed to load localization</div>;
    }
    if (loading) {
      return null;
    }
    return this.props.children;
  }
}

export default compose(
  connect(
    undefined,
    {
      getCompany,
      getAuthUser
    }
  ),
  withLocalize
)(Routine);
