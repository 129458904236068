import React from 'react';
import { Translate } from 'components/Localize';
import Branding from 'components/Branding';

const Headers = () => {
  return (
    <Branding>
      {resolve => (
        <React.Fragment>
          <h2 className="header__filter--title">
            {resolve('page.title') || <Translate id="find-greatest-job" />}
          </h2>
          {/*///////////////////////not provided by design//////////////////////*/}
          {/*<p>*/}
          {/*{resolve('page.subtitle') || (*/}
          {/*<Translate id="best-place-find-and-post-jobs" />*/}
          {/*)}*/}
          {/*</p>*/}
        </React.Fragment>
      )}
    </Branding>
  );
};

export default Headers;
