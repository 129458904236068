import React from 'react';
import Kind from 'app/Filter/Kind';
import Tag from 'app/Filter/Tag';
//import Sorter from 'app/Filter/Sorter';
import City from 'app/Filter/City';
import Branding from 'components/Branding';
import logo2 from 'styles/img/Logo2.svg';
import './InlineFilter.css';

const InlineFilter = () => {
  return (
    <Branding>
      {resolve => (
        <div className="inline-filter">
          <div className="inline-filter__box">
            <div className="container">
              <div className="inline-filter__container">
                <div className="inline-filter__wrapper">
                  <div className="inline-filter__fake-logo">
                    <img
                      alt="logo"
                      src={resolve('header.partner_logo') || logo2}
                    />
                  </div>
                  <div className="inline-filter__tabs">
                    <City />
                    <Kind />
                    <Tag />
                    {/*<Date />*/}
                  </div>
                </div>

                {/*<div className="inline-filter__select">
                  <Sorter />
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      )}
    </Branding>
  );
};

export default InlineFilter;
