import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Wide from './Wide';
import Narrow from './Narrow';
import AllPatern from '../AllPatern';

const Header = () => {
  return (
    <Route>
      {({ match }) => (
        <Switch>
          <Route path="/patern" component={AllPatern} />
          <Route path={`${match.url}/s`} component={Narrow} />
          <Route component={Wide} />
        </Switch>
      )}
    </Route>
  );
};

export default Header;
