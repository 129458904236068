import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components/Localize';
import style from './Partner.module.css';

const Partner = ({ vacancy }) => {
  const partner = vacancy.partner;
  if (!partner) {
    return null;
  }
  const termsLink = vacancy.company.gdpr_link;
  return (
    <div className={style['partner']}>
      <div className={style['partner-block']}>
        <Translate id="this-job-is-hosted-by" />
        {partner.logo ? (
          <img
            title={partner.title}
            alt=""
            src={partner.logo}
            className={style['partner-logo']}
          />
        ) : (
          <span>{partner.title}</span>
        )}
        {termsLink && (
          <a
            className={style['partner-link']}
            target="_blank"
            rel="noopener noreferrer"
            href={termsLink}
          >
            <Translate id="read-terms-data-protection" />
          </a>
        )}
      </div>
      {partner.description && (
        <div className={style['partner-description']}>
          {partner.description}
        </div>
      )}
    </div>
  );
};

Partner.propTypes = {
  vacancy: PropTypes.object.isRequired
};

export default Partner;
