import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MapComponent from 'components/Map';
import { Translate } from 'components/Localize';
import styleDetails from '../Details.module.css';
import style from './Map.module.css';
import Section from 'components/Section';
import { connect } from 'react-redux';
import { getNearest } from 'actions/vacancyActions';
import nearest from './Nearest';
import { CompanyResolver } from 'app/CompanyProvider';

const getLocation = point => {
  if (point) {
    return {
      lng: point.coordinates[0],
      lat: point.coordinates[1]
    };
  }
};
const getAddress = address => {
  return [
    address.postal_code,
    address.housenumber,
    address.street,
    address.city.name,
    address.city.state && address.city.state.name,
    address.city.state &&
      address.city.state.country &&
      address.city.state.country.name
  ]
    .filter(t => !!t)
    .join(' ');
};

const Map = ({ vacancy, getNearest, nearestVacancies }) => {
  useEffect(
    () => {
      getNearest(vacancy.pk);
    },
    [vacancy.pk]
  );

  return (
    <div className={styleDetails['vacancy-body-block']}>
      <Section>
        <h3 className={styleDetails['vacancy-body-block-title']}>
          <Translate
            id="here-you-can-find-company"
            data={{ company: vacancy.company.name }}
          />
        </h3>
      </Section>
      <div className={style['vacancy-body-map']}>
        <CompanyResolver>
          {({ resolve }) => (
            <MapComponent
              address={getAddress(vacancy.address)}
              location={getLocation(vacancy.address.point)}
              icon={
                vacancy.company_logo
                  ? vacancy.company_logo
                  : vacancy.company.logo
              }
            >
              {nearest(nearestVacancies, resolve)}
            </MapComponent>
          )}
        </CompanyResolver>
      </div>
    </div>
  );
};

Map.propTypes = {
  vacancy: PropTypes.object.isRequired
};

const mapStateToProps = (
  { nearestVacancies: { data } },
  { vacancy: { pk } }
) => ({
  nearestVacancies: data.byId[pk] || []
});

export default connect(
  mapStateToProps,
  { getNearest }
)(Map);
