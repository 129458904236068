import { parse } from 'qs';

const getApplyPath = () => {
  if (process.env.NODE_ENV !== 'production') {
    return 'http://localhost:3001/now';
  }
  return process.env.REACT_APP_APPLY_PATH;
};

class ApplyApi {
  getApplyLink(company, vacancy) {
    const { referrer } = parse(window.location.search.slice(1));
    const referrerPart = referrer ? `?referrer=${referrer}` : '';
    const companyPart = company ? `c/${company}/` : '';
    const applyPath = getApplyPath();
    return `${applyPath}/${companyPart}vacancy/${vacancy}${referrerPart}`;
  }
}

export default new ApplyApi();
