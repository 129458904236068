import { connect } from 'react-redux';

const getProperty = (obj, name) => {
  let result = obj;
  const parts = name.split('.');
  for (let i = 0; i < parts.length; i++) {
    result = result[parts[i]];
    if (result === undefined) return;
  }
  return result;
};

const resolveFor = obj => (key, defaultValue) => {
  if (obj) {
    const property = getProperty(obj, key);
    return property === undefined ? defaultValue : property;
  } else {
    return defaultValue;
  }
};

const Branding = ({ children, branding, company }) => {
  return children(resolveFor(branding), resolveFor(company));
};

const mapStateToProps = ({ company: { data } }) => ({
  branding: data && data.branding,
  company: data && data.company,
  partner: data && data.partner
});

export default connect(mapStateToProps)(Branding);
