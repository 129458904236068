import ServerApi from './ServerApi';
import { getDefaultLanguage } from 'lib/languages';

class AssistantApi extends ServerApi {
  getAssistantList() {
    return super.get(
      `/v1.3/developer/landing-settings/?language=${getDefaultLanguage()}`
    );
  }
}

export default new AssistantApi();
