import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { searchByCriteria } from 'actions/vacancyActions';
import { getItems, getPageCount } from 'reducers/vacancyReducer';
import Loader from 'components/Loader';
import { Error, Info } from 'components/Alert';
import { Translate } from 'components/Localize';
import Pagination from 'components/Pagination';
import Section from 'components/Section';
import styles from './Results.module.css';
import Vacancy from './Vacancy';
import LeftSideFilter from '../LeftSideFilter';
import { withRouter } from 'react-router-dom';
import withSearchState from 'app/withSearchState';

const getCriteria = (companyId, uriState, partner) => {
  const { search, sort, kind, tag, city, date } = uriState;
  return {
    text: search,
    sort,
    kind,
    tag,
    companyId,
    city,
    date,
    partner
  };
};

class Results extends React.Component {
  componentDidMount() {
    const { companyId, getUriState, partner } = this.props;
    this.props.searchByCriteria(
      getCriteria(companyId, getUriState(), partner),
      getUriState().page
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const {
        search,
        sort,
        page,
        kind,
        tag,
        city,
        date
      } = this.props.getUriState(this.props.location);
      const {
        search: prevSearch,
        sort: prevSort,
        kind: prevExKind,
        tag: prevTag,
        city: prevCity,
        date: prevDate
      } = this.props.getUriState(prevProps.location);
      if (
        search !== prevSearch ||
        sort !== prevSort ||
        //to compare array not only by length and by it's values
        kind.join('') !== prevExKind.join('') ||
        tag.join('') !== prevTag.join('') ||
        city !== prevCity ||
        date !== prevDate
      ) {
        const { companyId, getUriState, partner } = this.props;
        this.props.searchByCriteria(
          getCriteria(companyId, getUriState(), partner),
          page
        );
      }
    }
  }

  handlePageChange = page => {
    const { companyId, getUriState, partner } = this.props;
    //in order to show loaded inside prev result
    this.props
      .searchByCriteria(getCriteria(companyId, getUriState(), partner), page)
      .then(() => this.props.pushUriState({ page }));
  };

  renderInner() {
    const { loading, error } = this.props;

    if (error) {
      return <Error general />;
    }

    return (
      <Loader count="5" type="list" loading={loading}>
        {this.renderItems()}
      </Loader>
    );
  }

  render() {
    return (
      <Section>
        <div className={styles['flex']}>
          <LeftSideFilter />
          {this.renderInner()}
        </div>
      </Section>
    );
  }

  renderItems() {
    const { items, pageCount, getUriState } = this.props;
    const { page } = getUriState();

    if (!items) return null;

    if (items.length === 0) {
      return (
        <Info>
          <Translate id="there-were-no-search-results" />
        </Info>
      );
    }

    return (
      <React.Fragment>
        {items.map(vacancy => <Vacancy key={vacancy.pk} data={vacancy} />)}
        {pageCount > 1 && (
          <Pagination
            pageIndex={page}
            pageCount={pageCount}
            onChange={this.handlePageChange}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  { vacancy: { data, loading, error }, company },
  { getUriState }
) => {
  //Remove 'company' param from /recruiting/vacancy/ endpoint when company.branding.show_all_vacancies is True
  const companyId =
    company.data &&
    !company.data.branding.show_all_vacancies &&
    company.data.company &&
    company.data.company.pk;
  const partner = company.data && company.data.partner;
  const uriState = getUriState();
  return {
    companyId,
    partner,
    items: getItems(
      data,
      getCriteria(companyId, uriState, partner),
      uriState.page
    ),
    pageCount: getPageCount(data, getCriteria(companyId, uriState, partner)),
    loading: loading,
    error
  };
};

export default compose(
  withSearchState,
  withRouter,
  connect(
    mapStateToProps,
    { searchByCriteria }
  )
)(Results);
