import React from 'react';
// import Popup from 'reactjs-popup';
import LinkButton from 'components/LinkButton';
import './ModalFilter.css';

const ModalFilter = ({
  handleClose,
  trigger,
  children,
  // handleStop,
  header,
  closeOnDocumentClick = true,
  open = false,
  onClose,
  className
}) => {
  return (
    <div className="modal-filter">
      <div className="modal-filter__inner">
        <div className="modal-filter__header">
          <LinkButton className="modal-filter__close" onClick={handleClose}>
            &times;
          </LinkButton>
        </div>
        <div className="modal-filter__content">{children}</div>
      </div>
    </div>
  );
};

export default ModalFilter;
