import React from 'react';
import Dropdown from 'react-dropdown';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import style from './Select.module.css';

const Select = ({ className, invalid, ...props }) => (
  <Dropdown
    className={cx(style.select, { [style.invalid]: invalid }, className)}
    arrowClosed={
      <span>
        <FontAwesomeIcon icon={faAngleDown} />
      </span>
    }
    arrowOpen={
      <span>
        <FontAwesomeIcon icon={faAngleUp} />
      </span>
    }
    {...props}
  />
);

export default Select;
