import React from 'react';
import PropTypes from 'prop-types';
import DropDown from 'components/DropDown';
import { CompanyResolver } from 'app/CompanyProvider';
import CitySelect from '../CitySelect';
import { Translate } from 'components/Localize';
import style from './CityDropDown.module.css';

class CityDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.value
    };
  }

  handleChange = e => {
    this.setState({
      selected: e.target.value
    });
  };

  handleClear = () => {
    this.setState({
      selected: ''
    });
  };

  handleApply = () => {
    const { selected } = this.state;
    const { onChange, name } = this.props;
    onChange({
      target: { name, value: selected },
      persist: () => {}
    });
  };

  renderContent() {
    const { selected } = this.state;
    return (
      <CompanyResolver>
        {({ alias }) => (
          <div className="text-field field-space">
            <CitySelect
              value={selected}
              onChange={this.handleChange}
              relativeContainer
              companyId={alias}
            />
          </div>
        )}
      </CompanyResolver>
    );
  }

  getHeaderText() {
    const { selected } = this.state;
    return (
      <span>
        <Translate id="location" />
        {selected && ` · ${selected}`}
      </span>
    );
  }

  render() {
    const { selected } = this.state;
    return (
      <DropDown
        containerClassName={style['city-dropdown-container']}
        header={this.getHeaderText()}
        onApply={this.handleApply}
        onClear={this.handleClear}
        showClear={!!selected}
        active={!!selected}
      >
        {this.renderContent()}
      </DropDown>
    );
  }
}

CityDropDown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CityDropDown;
