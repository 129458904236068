import React from 'react';
import CityDropDown from './CityDropDown';
import withSearchState from 'app/withSearchState';

const City = ({ getUriState, pushUriStateWithoutPage }) => {
  const { city } = getUriState();
  const handleChange = e => {
    if (city !== e.target.value) {
      pushUriStateWithoutPage({
        city: e.target.value === '' ? undefined : e.target.value
      });
    }
  };
  //set key depend in city in order to component be updated
  return <CityDropDown key={city} value={city} onChange={handleChange} />;
};

export default withSearchState(City);
