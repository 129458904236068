import * as types from './types';
import tagApi from 'api/TagApi';
import { createAction } from 'lib/callAPI';

export function getTagList(companyId, partner) {
  return createAction({
    type: types.GET_TAG_LIST,
    callAPI: () => tagApi.getTagList(companyId, partner),
    shouldCallAPI: state => !state.tag.data && !state.tag.loading
  });
}

export function refetchTagList(companyId, partner) {
  return createAction({
    type: types.GET_TAG_LIST,
    callAPI: () => tagApi.getTagList(companyId, partner),
    //call if data is exist, if not getTagList will be called on demand.
    shouldCallAPI: state => state.tag.data
  });
}
