import React from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import Kind from './Kind';
import Tag from './Tag';
import MultiChannelReminder from './MultiChannelReminder';
import styles from './LeftSideFilter.module.css';

const LeftSideFilter = ({ location: { pathname } }) => {
  return (
    <div
      className={cx(styles['main'], {
        [styles['down-sticky']]: pathname !== '/'
      })}
    >
      <div className={styles['filter-container']}>
        <Kind />
      </div>
      <div className={styles['filter-container']}>
        <Tag />
      </div>
      <div className={styles['filter-container']}>
        <MultiChannelReminder />
      </div>
    </div>
  );
};

export default withRouter(LeftSideFilter);
