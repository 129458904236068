import React from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import geoApi from 'api/GeoApi';
import TextInput from '../TextInput';
import style from './CitySelect.module.css';

class CitySelect extends React.Component {
  state = {
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim();
    if (inputValue.length <= 1) return;
    geoApi.getCities(inputValue, 10).then(items => {
      this.setState({
        suggestions: items.map(item => item.name)
      });
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    const { onChange, name } = this.props;
    onChange({
      target: { name, value: newValue },
      persist: event.persist
    });
  };

  render() {
    const { suggestions } = this.state;
    const { value } = this.props;
    const inputProps = {
      ...this.props,
      value: value || '',
      onChange: this.handleChange
    };
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        getSuggestionValue={value => value}
        renderSuggestion={value => value}
        renderInputComponent={inputProperty => <TextInput {...inputProperty} />}
        inputProps={inputProps}
        theme={style}
      />
    );
  }
}

CitySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default CitySelect;
