import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Checkbox.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Checkbox = ({ children, value, ...props }) => {
  return (
    <label className="checkbox-label">
      <input type="checkbox" checked={value} className="checkbox" {...props} />
      {children}
      <i
        className={cx(
          { 'small-checkbox': props.size === 'small' },
          'checkbox-status'
        )}
      >
        <FontAwesomeIcon icon={faCheck} className="check-box" />
      </i>
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired
};

export default Checkbox;
