import React from 'react';
import PropTypes from 'prop-types';
import { getKindList } from 'actions/kindActions';
import { connect } from 'react-redux';

class Kind extends React.Component {
  componentDidMount() {
    this.props.getKindList();
  }

  render() {
    const { name, loading, error, data } = this.props;
    if (loading || error || !data) {
      return null;
    }

    const field = data.find(field => field.value === name);
    if (!field) {
      return name;
    }
    return field.title;
  }
}

Kind.propTypes = {
  name: PropTypes.string
};

const mapStateToProps = ({ kind: { data, error, loading } }) => ({
  loading: loading,
  error: error,
  data: data
});

export default connect(
  mapStateToProps,
  { getKindList }
)(Kind);
