import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import { Error } from 'components/Alert';
import { getFooterHtml } from 'actions/footerHtmlActions';

const CustomFooter = ({ htmlUrl, loading, data, error, getFooterHtml }) => {
  useEffect(() => {
    getFooterHtml(htmlUrl);
  }, []);

  if (error) {
    return <Error general />;
  }
  if (loading) {
    return <Loader />;
  }

  return <div dangerouslySetInnerHTML={{ __html: data }} />;
};

CustomFooter.propTypes = {
  htmlUrl: PropTypes.string.isRequired
};

const mapStateToProps = ({ footerHtml: { loading, data, error } }) => ({
  loading,
  data,
  error
});

export default connect(
  mapStateToProps,
  { getFooterHtml }
)(CustomFooter);
