import ServerApi from './ServerApi';

class LocalizationApi extends ServerApi {
  getLocalization(company) {
    const companyPart = company ? `&company=${company}` : '';
    return super.get(`/developer/app-localization/?page=apply${companyPart}`);
  }
}

export default new LocalizationApi();
