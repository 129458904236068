import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components/Localize';
import Branding from 'components/Branding';

const VideosProvider = ({ vacancy, children }) => {
  return (
    <Branding>
      {resolve => {
        const isRecruiterVideoEnabled = resolve(
          'recruiter.video_enabled',
          true
        );
        const videos = [
          vacancy.recruiter_video && isRecruiterVideoEnabled
            ? {
                preview: vacancy.recruiter_video.cover,
                url: vacancy.recruiter_video.video,
                title: vacancy.recruiter_video.title || (
                  <Translate id="recruiter-video" />
                ),
                duration: vacancy.recruiter_video.duration
              }
            : null,
          vacancy.video
            ? {
                preview: vacancy.video.preview_image.image,
                url: vacancy.video.video
              }
            : null,
          vacancy.extra_video
            ? {
                preview: vacancy.extra_video.thumbnail_url,
                url: vacancy.extra_video.embed_url,
                type: vacancy.extra_video.kind
                  ? vacancy.extra_video.kind.toLowerCase()
                  : ''
              }
            : null,
          vacancy.immersive_video
            ? {
                preview: vacancy.immersive_video.immersive_video_preview,
                url: vacancy.immersive_video.url,
                type: vacancy.immersive_video.kind
                  ? vacancy.immersive_video.kind.toLowerCase()
                  : ''
              }
            : null
        ].filter(t => !!t);
        return children(videos);
      }}
    </Branding>
  );
};

VideosProvider.propTypes = {
  vacancy: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired
};

export default VideosProvider;
