export const SEARCH_VACANCY_LIST = 'SEARCH_VACANCY_LIST';

export const GET_KIND_LIST = 'GET_KIND_LIST';
export const GET_TAG_LIST = 'GET_TAG_LIST';

export const GET_COMPANY = 'GET_COMPANY';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';

export const GET_APPLICATION_STATUSES = 'GET_APPLICATION_STATUSES';
export const GET_MY_APPLICATIONS = 'GET_MY_APPLICATIONS';

export const SUBMIT_PASSWORD = 'SUBMIT_PASSWORD';
export const VALIDATE_PASSWORD_TOKEN = 'VALIDATE_PASSWORD_TOKEN';

export const GET_COUNTIES = 'GET_COUNTIES';

export const GET_VACANCY = 'GET_VACANCY';
export const GET_RELATED_VACANCY = 'GET_RELATED_VACANCY';

export const GET_ASSISTANT_LIST = 'GET_ASSISTANT_LIST';
export const GET_NEAREST_VACANCIES = 'GET_NEAREST_VACANCIES';

export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';

export const GET_VIDEO = 'GET_VIDEO';

export const GET_AUDIO = 'GET_AUDIO';

export const GET_SKILLS = 'GET_SKILLS';

export const GET_FOOTER_HTML = 'GET_FOOTER_HTML';
