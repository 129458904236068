import React from 'react';
import cx from 'classnames';
import Spin from './Spin';
import './Loader.css';
import Placeholder from './Placeholder';
// import Section from '../Section';

const Loader = ({ loading = true, children, full, count, type }) => {
  const contextClasses = ['loading-content', { hidden: loading }];
  const classes = ['loader', { full: full }];
  const loader = <Placeholder count={count} type={type} />;

  const content = () => {
    if (type) {
      return loading ? (
        loader
      ) : (
        <div className={cx(contextClasses)}>{children}</div>
      );
    } else {
      return (
        <React.Fragment>
          {loading && <Spin />}
          <div className={cx(contextClasses)}>{children}</div>
        </React.Fragment>
      );
    }
  };

  return <div className={cx(classes)}>{content()}</div>;
};

export default Loader;
