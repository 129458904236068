import React from 'react';
import Loadable from 'react-loadable';
import Loader from 'components/Loader';
import { Error } from 'components/Alert';

export default function asyncComponent(
  importComponent,
  loaderFullScreen = false
) {
  return Loadable({
    loader: importComponent,
    loading(props) {
      if (props.error) {
        return <Error general retry={props.retry} />;
      } else {
        return <Loader full={loaderFullScreen} />;
      }
    }
  });
}
