export const parse = value => {
  const date = new Date(value);
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  };
};

const pad = function(num) {
  const norm = Math.floor(Math.abs(num));
  return (norm < 10 ? '0' : '') + norm;
};

export const format = ({ year, month, day }) =>
  `${year}-${pad(month)}-${pad(day)}`;
