import React from 'react';
import PropTypes from 'prop-types';
import ApplyButton from '../ApplyButton';
import Applied from '../Applied';
import CityTagDuration from '../CityTagDuration';
import KindCompany from '../KindCompany';
import Title from '../Title';
import style from './ForDetails.module.css';

const ForDetails = ({ vacancy }) => {
  return (
    <div className={style['for-details']}>
      <div className={style['logo']}>
        <img
          src={
            vacancy.company_logo ? vacancy.company_logo : vacancy.company.logo
          }
          alt="logo"
        />
      </div>

      <div className={style['content-first']}>
        {/*<Kind vacancy={vacancy} className={style['phone-visible']} />*/}

        <Title vacancy={vacancy} />
        <KindCompany vacancy={vacancy} className={style['phone-hidden']} />
        {/*<Company vacancy={vacancy} className={style['phone-visible']} />*/}
        <div className={style['apply-button-tablet']}>
          <Applied vacancy={vacancy}>
            <ApplyButton
              vacancyId={vacancy.pk}
              vacancyCompanyId={vacancy.company.pk}
              externalLink={vacancy.external_link}
            />
          </Applied>
        </div>
      </div>
      <CityTagDuration
        vacancy={vacancy}
        className={style['city-tag-duration']}
      />
      <div className={style['apply-wrapper']}>
        <Applied vacancy={vacancy}>
          <ApplyButton
            vacancyId={vacancy.pk}
            vacancyCompanyId={vacancy.company.pk}
            externalLink={vacancy.external_link}
          />
        </Applied>
      </div>
    </div>
  );
};

ForDetails.propTypes = {
  vacancy: PropTypes.object.isRequired
};

export default ForDetails;
