import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Autosuggest from 'react-autosuggest';
import TextInput from '../TextInput/index';
import style from './AutoSuggest.module.css';

class AutoSuggest extends React.Component {
  state = {
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim();
    if (inputValue.length <= 1) return;

    this.props.fetchFunc(inputValue).then(items => {
      this.setState({
        suggestions: items
      });
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    this.props.onChange({
      target: { name: this.props.name, value: newValue },
      persist: event.persist
    });
  };

  handleSuggestionSelected = (e, { suggestion, suggestionValue, method }) => {
    this.props.onSuggestionSelect &&
      this.props.onSuggestionSelect(suggestionValue, suggestion, method);
  };

  handleRemoveClick = () => {
    this.props.onChange({
      target: { name: this.props.name, value: '' },
      persist: () => {}
    });
    this.props.onSuggestionSelect && this.props.onSuggestionSelect('');
  };

  render() {
    const {
      className,
      containerClassName,
      inputClassName,
      isIconPath,
      iconClassName,
      value,
      getSuggestionValue,
      renderSuggestion,
      fetchFunc,
      onSuggestionSelect,
      relativeContainer,
      ...props
    } = this.props;
    const { suggestions } = this.state;
    const inputProps = {
      ...props,
      className: cx(style['input'], inputClassName),
      value: value || '',
      onChange: this.handleChange
    };
    return (
      <div className={cx(className, style['autosuggest-wrapper'])}>
        {isIconPath ? (
          <i
            className={cx(
              'icon icon-search',
              style['autosuggest-icon'],
              iconClassName
            )}
          >
            <img src={isIconPath} alt="search" />
          </i>
        ) : null}

        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={inputProps => (
            <TextInput rounded {...inputProps} />
          )}
          inputProps={inputProps}
          theme={{
            ...style,
            suggestionsContainer: cx(
              style.suggestionsContainer,
              containerClassName,
              { [style['relative-container']]: relativeContainer }
            )
          }}
        />
        {value && (
          <i
            className={cx('icon icon-times', style['autosuggest-remove'])}
            onClick={this.handleRemoveClick}
          />
        )}
      </div>
    );
  }
}

AutoSuggest.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  fetchFunc: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func,
  renderSuggestion: PropTypes.func,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func,
  relativeContainer: PropTypes.bool
};

AutoSuggest.defaultProps = {
  getSuggestionValue: value => value,
  renderSuggestion: value => value,
  relativeContainer: false
};

export default AutoSuggest;
