import ServerApi from './ServerApi';

class FileApi extends ServerApi {
  constructor() {
    super('text/html');
  }

  getFooterHtml(url) {
    return super.get(url, false, true);
  }
}

export default new FileApi();
