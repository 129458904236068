import * as types from 'actions/types';
import { createReducer, successType } from 'lib/callAPI';

/**
 * Temporary solution till we will have possibility to get all attachments by single request
 */
function getUnique(arr, comp) {
  const unique = arr
    .map(e => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);
  return unique;
}

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case types.SET_ATTACHMENTS:
      return [...getUnique(action.attachments, 'pk')];
    case successType(types.SET_ATTACHMENTS):
      return getUnique([...state, ...action.response], 'pk');
    default:
      return state;
  }
};

export default createReducer([types.SET_ATTACHMENTS], dataReducer);
