import * as types from 'actions/types';
import initialState from 'store/initialState';

export default (state = initialState.currentUser, action) => {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return {
        ...(state || {}),
        ...action.user
      };
    case types.LOGOUT:
      return initialState.currentUser;
    default:
      return state;
  }
};
