import React from 'react';
import cx from 'classnames';
import { AutoSuggest } from 'components/Form';
import { Translate } from 'components/Localize';
import geoApi from 'api/GeoApi';
import style from './VacancySelect.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faClipboard,
  faIndustry,
  faMapMarkerAlt,
  faStar,
  faUserClock
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

export const SUGGESTION_TYPE = {
  COMPANY: 'COMPANY',
  VACANCY: 'VACANCY',
  LOCATION: 'LOCATION',
  KIND: 'KIND',
  INDUSTRY: 'INDUSTRY'
};

const icons = {
  [SUGGESTION_TYPE.COMPANY]: faBuilding,
  [SUGGESTION_TYPE.VACANCY]: faClipboard,
  [SUGGESTION_TYPE.LOCATION]: faMapMarkerAlt,
  [SUGGESTION_TYPE.KIND]: faUserClock,
  [SUGGESTION_TYPE.INDUSTRY]: faIndustry
};

const VacancySelect = ({ companyId, dispatch, ...props }) => (
  <Translate>
    {({ translate }) => (
      <AutoSuggest
        isIconPath={props.isIconPath}
        fetchFunc={value => geoApi.getSuggestion(value, 10, companyId)}
        getSuggestionValue={value => value.text}
        renderSuggestion={value => (
          <span>
            <span
              className={cx('fa-layers fa-fw', style['vacancy-select-icon'])}
            >
              <FontAwesomeIcon icon={icons[value.kind]} />
              {value.has_branding && (
                <FontAwesomeIcon
                  icon={faStar}
                  transform="shrink-10 up-10 right-8"
                  className={style['vacancy-select-icon--branded']}
                />
              )}
            </span>
            {value.text}
          </span>
        )}
        placeholder={translate('search-software-engineer')}
        {...props}
      />
    )}
  </Translate>
);

const mapStateToProps = ({ company: { data } }) => ({
  companyId: data && data.company && data.company.pk
});

export default connect(mapStateToProps)(VacancySelect);
