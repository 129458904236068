import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { getAssistantList } from 'actions/assistantAction';
import { Translate } from 'components/Localize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faXing,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import style from './FooterLinks.module.css';

const icons = {
  'facebook-f': faFacebookF,
  twitter: faTwitter,
  youtube: faYoutube,
  instagram: faInstagram,
  linkedin: faLinkedinIn,
  xing: faXing
};

const FooterLinks = ({
  getAssistantList,
  socialLinks,
  links,
  legal,
  download
}) => {
  useEffect(() => {
    getAssistantList();
  }, []);

  return (
    <div className={style['footer']}>
      <div className={style['footer-box']}>
        <p className={style['footer-title']}>
          <Translate id="links" />
        </p>
        <div className={style['footer-links']}>
          {links.map(link => (
            <a
              href={link['absolute_url_path']}
              key={link.title}
              className={style['footer-link']}
            >
              {link.title}
            </a>
          ))}
        </div>
      </div>
      <div className={style['footer-box']}>
        <p className={style['footer-title']}>
          <Translate id="legal" />
        </p>
        <div className={style['footer-links']}>
          {legal.map(link => (
            <a
              href={link['absolute_url_path']}
              key={link.title}
              className={style['footer-link']}
            >
              {link.title}
            </a>
          ))}
        </div>
      </div>
      <div className={style['footer-box']}>
        <p className={style['footer-title']}>
          <Translate id="download" />
        </p>
        <div className={style['footer-links']}>
          {download.map(link => (
            <a
              href={link['absolute_url_path']}
              key={link.title}
              className={style['footer-link']}
            >
              {link.title}
            </a>
          ))}
        </div>
      </div>
      <div className={style['footer-box']}>
        <div className={style['footer-title']}>
          <Translate id="contact-us" />
        </div>
        <div
          className={cx(style['footer-links'], style['social-links__icons'])}
        >
          <div className={style['social-box']}>
            {socialLinks.map(links => (
              <a
                href={links.link}
                key={links.kind}
                className={cx(
                  style['social-box__link'],
                  style[`social-box__link--${links.kind}`]
                )}
              >
                <FontAwesomeIcon icon={icons[links.kind]} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ assistants: { data } }) => {
  return {
    socialLinks: (data && data.social_links) || [],
    links:
      (data && data.menu && data.menu.footer && data.menu.footer.links) || [],
    legal:
      (data && data.menu && data.menu.footer && data.menu.footer.legal) || [],
    download:
      (data && data.menu && data.menu.footer && data.menu.footer.download) || []
  };
};

export default connect(mapStateToProps, { getAssistantList })(FooterLinks);
