import { combineReducers } from 'redux';
import vacancy from './vacancyReducer';
import kind from './kindReducer';
import tag from './tagReducer';
import company from './companyReducer';
import currentUser from './currentUserReducer';
import myApplications from './myApplicationsReducer';
import applicationStatuses from './applicationStatusesReducer';
import validatePassword from './validatePasswordReducer';
import countries from './countriesReducer';
import relatedVacancies from './relatedVacanciesReducer';
import assistants from './assistantReducer';
import nearestVacancies from './nearestVacanciesReducer';
import attachments from './attachmentsReducer';
import video from './videoReducer';
import skills from './skillsReducer';
import audio from './audioReducer';
import footerHtml from './footerHtmlReducer';

export default combineReducers({
  vacancy,
  kind,
  tag,
  company,
  currentUser,
  myApplications,
  applicationStatuses,
  validatePassword,
  countries,
  relatedVacancies,
  assistants,
  attachments,
  video,
  skills,
  audio,
  nearestVacancies,
  footerHtml
});
