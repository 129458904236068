import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tag } from 'components/Format';
import { Translate } from 'components/Localize';
import style from './KindCompany.module.css';

const Kind = ({ vacancy, className }) => {
  if (vacancy.industry) {
    return (
      <span className={cx(style['tag-badge'], className)}>
        <Tag slug={vacancy.industry.slug} />
      </span>
    );
  }
  return null;
};

const Company = ({ vacancy, className }) => {
  return (
    <span className={cx(style['company'], className)}>
      <Translate id="at" /> {vacancy.company.name}
    </span>
  );
};

const OriginalCompanyName = ({ vacancy, className }) => {
  if (vacancy.original_company_name) {
    return (
      <span
        className={cx(style['company'], className)}
        dangerouslySetInnerHTML={{
          __html: `(${vacancy.original_company_name})`
        }}
      />
    );
  }
  return null;
};

const KindCompany = ({ vacancy, className, companyClassName }) => {
  return (
    <ul className={cx(style['kind-company'], className)}>
      {vacancy.industry && (
        <li>
          <Kind vacancy={vacancy} />
        </li>
      )}
      <li>
        <Company vacancy={vacancy} className={companyClassName} />
      </li>
      {vacancy.original_company_name && (
        <li>
          <OriginalCompanyName vacancy={vacancy} className={companyClassName} />
        </li>
      )}
    </ul>
  );
};

KindCompany.propTypes = {
  vacancy: PropTypes.object.isRequired,
  className: PropTypes.string,
  companyClassName: PropTypes.string
};

Kind.propTypes = {
  vacancy: PropTypes.object.isRequired,
  className: PropTypes.string
};

Company.propTypes = {
  vacancy: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default KindCompany;
export { Kind, Company };
