import React from 'react';
import FilterPanel from './FilterPanel';
import Headers from './Headers';
import Common from '../Common';
// import { SmallMobile } from 'components/MediaQuery';
import '../header.css';

const Wide = () => {
  return (
    <header className="header">
      {/*<div className="header__image">*/}
      {/*<img  src={back} alt="girl"/>*/}
      {/*</div>*/}

      <Common>
        {/*<SmallMobile>*/}
        {/*{matches =>*/}
        {/*matches ? (*/}
        {/*<div className="filter-panel-mobile">*/}
        {/*<Headers />*/}
        {/*<div className="filter-panel-mobile-form">*/}
        {/*<FilterPanel />*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*) : (*/}
        <div className="header__filter">
          <Headers />
          <FilterPanel />
        </div>
        {/*)*/}
        {/*}*/}
        {/*</SmallMobile>*/}
      </Common>
    </header>
  );
};

export default Wide;
