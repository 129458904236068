import ServerApi from './ServerApi';
import { getDefaultLanguage } from 'lib/languages';

class TagApi extends ServerApi {
  getTagList(companyId, partner) {
    const company = companyId ? `&company_id=${companyId}` : '';
    const partnerOd = partner ? `&partner=${partner}` : '';
    return super.get(
      `/v1/tagging/tag/?language=${getDefaultLanguage()}${company}${partnerOd}`
    );
  }
}

export default new TagApi();
