import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCountries } from 'actions/countiesActions';
import cx from 'classnames';
import { Error } from 'components/Alert';
import Loader from 'components/Loader';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import './PhoneInput.css';

const formatCountries = list =>
  list
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(item => [
      item.name,
      item.code.toLowerCase(),
      item.phone_prefix.slice(1)
    ]);

class PhoneInput extends React.Component {
  state = {
    number: '',
    iso2: 'de',
    dialCode: '49'
  };

  constructor(props) {
    super(props);
    this.telInput = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.countries.data && props.value !== PhoneInput.getPhone(state)) {
      const country = props.countries.data.find(country =>
        props.value.startsWith(country.phone_prefix)
      );
      if (country) {
        return {
          number: props.value.replace(country.phone_prefix, ''),
          dialCode: country.phone_prefix.slice(1),
          iso2: country.code.toLowerCase()
        };
      }
    }
    return null;
  }

  static getPhone(state) {
    return `+${state.dialCode}${state.number}`;
  }

  componentDidMount() {
    this.props.getCountries();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.iso2 !== prevState.iso2) {
      this.telInput.current.setFlag(this.state.iso2);
    }
  }

  handlePhoneNumberChange = (_, value) => {
    //do not setState because it calls getDerivedStateFromProps and number will be set from props.value
    this.fireChange({ dialCode: this.state.dialCode, number: value });
  };

  handleSelectFlag = (_, obj) => {
    //do not setState because it calls getDerivedStateFromProps and number will be set from props.value
    this.fireChange({ dialCode: obj.dialCode, number: this.state.number });
  };

  fireChange({ dialCode, number }) {
    const { onChange, name } = this.props;
    onChange({
      target: { name, value: PhoneInput.getPhone({ dialCode, number }) },
      persist: () => {}
    });
  }

  render() {
    const {
      countries,
      onChange,
      onBlur,
      className,
      name,
      rounded = false,
      value,
      ...props
    } = this.props;
    const classNames = {
      'form-input': true,
      'non-rounded': !rounded
    };
    if (countries.error) {
      return <Error transparent general retry={this.props.getCountries} />;
    }
    if (countries.loading || !countries.data) {
      return <Loader />;
    }

    const { number, iso2 } = this.state;
    return (
      <IntlTelInput
        countriesData={formatCountries(countries.data)}
        nationalMode={false}
        separateDialCode
        fieldName={name}
        formatOnInit={false}
        format={false}
        defaultCountry={iso2}
        preferredCountries={['de']}
        containerClassName="intl-tel-input phone-input-container"
        inputClassName={cx(classNames, className)}
        onPhoneNumberChange={this.handlePhoneNumberChange}
        onSelectFlag={this.handleSelectFlag}
        onPhoneNumberBlur={(_, value) =>
          onBlur &&
          onBlur({
            target: { name, value },
            persist: () => {}
          })
        }
        ref={this.telInput}
        value={number}
        {...props}
      />
    );
  }
}

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = ({ countries }) => ({ countries });

export default connect(
  mapStateToProps,
  { getCountries }
)(PhoneInput);
