import React from 'react';
import VacancySelect, { SUGGESTION_TYPE } from 'app/Filter/VacancySelect';
import withSearchState from 'app/withSearchState';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './Search.css';

class Search extends React.Component {
  suggestionSelected = false;

  constructor(props) {
    super(props);
    const { search } = props.getUriState();
    this.state = {
      value: search
    };
  }

  doSearch = value => {
    this.props.pushUriStateWithoutPage({
      search: value === '' ? undefined : value
    });
  };

  handleChange = e => {
    this.setState({
      value: e.target.value
    });
  };

  handleKeyDown = e => {
    if (e.keyCode === 13 && !this.suggestionSelected) {
      this.doSearch(this.state.value);
    }
    this.suggestionSelected = false;
  };

  handleBlur = () => {
    this.doSearch(this.state.value);
    this.props.handleChangeLogo();
  };

  handleSuggestionSelect = (value, suggestion) => {
    this.suggestionSelected = true;
    if (suggestion.kind === SUGGESTION_TYPE.LOCATION) {
      //reset previously defined search text.
      this.props.pushUriStateWithoutPage({ city: value, search: undefined });
      this.setState({ value: '' });
    } else if (suggestion.kind === SUGGESTION_TYPE.KIND) {
      const kind = this.props.kindList
        .filter(kind => kind.value === suggestion.filter_params.value)
        .map(kind => kind.value);
      this.props.pushUriStateWithoutPage({ kind, search: undefined });
      this.setState({ value: '' });
    } else if (suggestion.kind === SUGGESTION_TYPE.INDUSTRY) {
      this.props.pushUriStateWithoutPage({
        tag: [suggestion.filter_params.value],
        search: undefined
      });
      this.setState({ value: '' });
    } else {
      this.doSearch(value);
    }
  };

  render() {
    const { value } = this.state;
    return (
      <VacancySelect
        className="search-box"
        onChange={this.handleChange}
        onSuggestionSelect={this.handleSuggestionSelect}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        onFocus={this.props.handleChangeLogo}
        value={value}
        inputClassName="search-filter"
        isIconPath={this.props.isIconPath}
      />
    );
  }
}

const mapStateToProps = ({ kind: { data } }) => ({
  kindList: data || []
});

export default compose(
  withSearchState,
  connect(mapStateToProps)
)(Search);
