import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import applyApi from 'api/ApplyApi';
import cx from 'classnames';
import Header from './Header';
import { CompanyResolver } from 'app/CompanyProvider';
import Branding from 'components/Branding';
import { Route } from 'react-router-dom';
import style from './Vacancy.module.css';
import { connect } from 'react-redux';

const VacancyLink = ({
  companyResolver,
  alias,
  companyData,
  keepCompaniesBranding,
  brandCompanyId,
  companyHistory
}) => {
  let companyId = brandCompanyId;
  //if branding.keep_companies_branding IS TRUE
  //OR if branding.keep_companies_branding IS null
  //OR page without branding (e.g. videobewerbung.de/apply)
  if (keepCompaniesBranding === null || keepCompaniesBranding === true) {
    companyId = companyData.company.pk;
  }

  const handleClick = resolve => {
    return () => {
      if (resolve('apply.vacancy_list.detail_page_enabled', true)) {
        const href = companyResolver(`/s/vacancy/${companyData.pk}`);

        if (resolve('apply.vacancy_list.open_vacancy_in_new_tab', false)) {
          window.open(href, '_blank');
        } else {
          companyHistory.push(href);
        }
      } else {
        const href =
          (alias && companyData.external_link) ||
          applyApi.getApplyLink(companyId, companyData.pk);

        if (resolve('apply.vacancy_list.open_vacancy_in_new_tab', false)) {
          window.open(href, '_blank');
        } else {
          window.location = href;
        }
      }
    };
  };
  return (
    <Branding>
      {resolve => {
        return (
          //vacancy-detail-link class for searching elements in DOM (by SEO)
          <div
            className={cx(style['vacancy'], 'vacancy-detail-link')}
            onClick={handleClick(resolve)}
          >
            <Header vacancy={companyData} />
          </div>
        );
      }}
    </Branding>
  );
};
const MemoiseVacancyLink = props =>
  useMemo(() => <VacancyLink {...props} />, []);

const Vacancy = ({ data, brandCompanyId, keepCompaniesBranding }) => {
  return (
    <Route>
      {({ history }) => (
        <CompanyResolver>
          {({ resolve, alias }) => (
            <MemoiseVacancyLink
              companyResolver={resolve}
              alias={alias}
              companyData={data}
              companyHistory={history}
              brandCompanyId={brandCompanyId}
              keepCompaniesBranding={keepCompaniesBranding}
            />
          )}
        </CompanyResolver>
      )}
    </Route>
  );
};
Vacancy.propTypes = {
  data: PropTypes.object.isRequired
};

const mapStateToProps = ({ company }) => ({
  brandCompanyId:
    company.data && company.data.company && company.data.company.pk,
  keepCompaniesBranding:
    company.data && company.data.branding.keep_companies_branding
});

export default connect(mapStateToProps)(Vacancy);
