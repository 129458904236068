import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getById } from 'actions/vacancyActions';
import { Error } from 'components/Alert';
import Loader from 'components/Loader';
import Map from './Map';
import HeaderForDetails from '../Header/ForDetails';
import Photos from '../Photos';
import Videos from '../Videos';
import Description from './Description';
import Partner from './Partner';
import RelatedJobs from './RelatedJobs';
import VideosProvider from '../Videos/VideosProvider';
import style from './Details.module.css';
import CallBackSection from './CallBackSection/CallBackSection';
import Section from 'components/Section';
import JobPostingJSON from './JobPostingJSON';

const Details = ({ match, getById, loading, error, vacancy }) => {
  useEffect(
    () => {
      getById(match.params.id);
      window.scrollTo(0, 0);
    },
    [match.params.id]
  );

  if (error) {
    return (
      <Section>
        <Error general />
      </Section>
    );
  }
  if (loading || !vacancy) {
    return (
      <Section>
        <Loader count="1" type="details" />
      </Section>
    );
  }
  return (
    <div className={style['vacancy-details']}>
      <Section>
        <JobPostingJSON vacancy={vacancy} />
        <HeaderForDetails vacancy={vacancy} />
      </Section>
      <Section>
        <VideosProvider vacancy={vacancy}>
          {videos => {
            return (
              <React.Fragment>
                <div className={style['vacancy-photo-video']}>
                  <Videos
                    videos={videos}
                    singlePhoto={vacancy.image_list.length === 1}
                    companyLogo={
                      vacancy.company_logo
                        ? vacancy.company_logo
                        : vacancy.company.logo
                    }
                  />
                  <Photos
                    photos={vacancy.image_list}
                    hasVideos={videos.length > 0}
                  />
                </div>
              </React.Fragment>
            );
          }}
        </VideosProvider>
      </Section>
      <Section>
        <Description vacancy={vacancy} />
      </Section>
      <Section>
        <Partner vacancy={vacancy} />
      </Section>
      <CallBackSection className="call-body__vacancy" />
      <Map vacancy={vacancy} />
      <Section>
        <RelatedJobs companyId={vacancy.company.pk} vacancyId={vacancy.pk} />
      </Section>
    </div>
  );
};

const mapStateToProps = ({ vacancy: { data, loading, error } }, { match }) => ({
  loading,
  error,
  vacancy: data.byId[match.params.id]
});

export default connect(
  mapStateToProps,
  { getById }
)(Details);
