import React from 'react';
import './Button.css';
import cx from 'classnames';
import Spin from 'components/Loader/Spin';

const Button = ({ loading, className, children, disabled, ...props }) => {
  const loadingClassName = {
    'btn-loading': loading
  };
  const contextClasses = { 'btn-content-loading': loading };
  return (
    <button
      className={cx(className, 'btn')}
      disabled={disabled || loading}
      {...props}
    >
      {loading && <Spin className={cx(loadingClassName)} light />}
      <span className={cx(contextClasses)}>{children}</span>
    </button>
  );
};

export default Button;
