import React from 'react';

import ReactSVG from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedinIn,
  faXing
} from '@fortawesome/free-brands-svg-icons';
import search from '../../styles/img/icons/search-icon.svg';
import arrows from '../../styles/img/icons/arrows-icons.svg';
import logo from '../../styles/img/Logo.svg';
import { MultiSelect } from 'react-selectize';
// import 'react-selectize/themes/default.css';

const AllPatern = () => {
  return (
    <div className="container">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <h1>Headlines 1</h1>
      <h2>Headlines 2</h2>
      <h3>Headlines 3</h3>
      <h4>Headlines 4</h4>
      <p className="paragraph paragraph--middle">Headlines 1</p>
      <p className="paragraph paragraph--small">Headlines 1</p>
      <p className="header-link">FOR COMPANIES</p>
      <div className="black-box">
        <p className="footer-link">footer-link</p>
        <p className="footer-link">footer-link</p>
        <p className="footer-link">footer-link</p>
      </div>
      <div className="social-box">
        <p className="social-box__link social-box__link--facebook">
          <FontAwesomeIcon icon={faFacebookF} />
        </p>
        <p className="social-box__link social-box__link--twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </p>
        <p className="social-box__link social-box__link--youtube">
          <FontAwesomeIcon icon={faYoutube} />
        </p>
        <p className="social-box__link social-box__link--instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </p>
        <p className="social-box__link social-box__link--linkedin">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </p>
        <p className="social-box__link social-box__link--xing">
          <FontAwesomeIcon icon={faXing} />
        </p>
      </div>

      <button className="button button--full">Label</button>
      <button className="button button-transparent">REGISTRIEREN</button>
      <button className="button button-search">
        <span className="button-search__label">Label</span>
        <ReactSVG src={search} />
      </button>
      <button className="button button-search ">
        <ReactSVG src={search} />
        <span className="button-search__label button-search__label--right">
          Label
        </span>
      </button>
      <button className="button button-arrow">
        <span className="button-arrow__label">Label</span>
        <ReactSVG src={arrows} />
      </button>
      <div className="text-field text-field--error">
        <span>Was ist dein Wunschjob?</span>
        <input type="text" placeholder="Wunschjob" />
      </div>
      <div className="text-field text-field--success">
        <span>Was ist dein Wunschjob?</span>
        <input type="text" placeholder="Wunschjob" />
      </div>
      <div className="text-field ">
        <span>Was ist dein Wunschjob?</span>
        <input type="text" placeholder="Wunschjob" />
      </div>
      <div className="text-field ">
        <span>Was ist dein Wunschjob?</span>
        <input type="text" disabled={true} placeholder="Wunschjob" />
      </div>
      <div className="select-field select-field--error">
        <span>Was ist dein Wunschjob?</span>
        <MultiSelect
          placeholder="Anstellungsart"
          transitionEnter={true}
          transitionLeave={true}
        >
          <option value="Weiß nicht">Weiß nicht</option>
          <option value="Vollzeit">Vollzeit</option>
          <option value="Teilzeit">Teilzeit</option>
        </MultiSelect>
      </div>
    </div>
  );
};

export default AllPatern;
