import React from 'react';
import PropTypes from 'prop-types';
import style from './LinkButton.module.css';
import cx from 'classnames';

const LinkButton = ({ className, ...props }) => {
  return (
    <button
      type="button"
      className={cx(className, style['link-button'])}
      {...props}
    />
  );
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default LinkButton;
