import ServerApi from './ServerApi';
import { getDefaultLanguage } from 'lib/languages';

class GeoApi extends ServerApi {
  getCities(search, limit, companyId) {
    const company = companyId ? `&company=${companyId}` : '';
    return super.get(
      `/geo/city/?search=${encodeURIComponent(search)}&limit=${limit}${company}`
    );
  }

  getCountries() {
    return super.get(`/geo/country/`);
  }

  getSuggestion(query, limit, companyId) {
    const company = companyId ? `&company_id=${companyId}` : '';
    return super.get(
      `/v1/recruiting/suggestions/?language=${getDefaultLanguage()}&query=${query}&limit=${limit}${company}`
    );
  }
}

export default new GeoApi();
