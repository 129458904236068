import React from 'react';
import Popup from 'reactjs-popup';
import LinkButton from 'components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import './Modal.css';

const Modal = ({
  trigger,
  children,
  closeButtonArrow,
  header,
  closeOnDocumentClick = true,
  open = false,
  onClose,
  maxWidth,
  className
}) => {
  const contentStyle = {};
  if (maxWidth) {
    contentStyle['maxWidth'] = maxWidth;
  }
  return (
    <Popup
      open={open}
      onClose={onClose}
      trigger={trigger}
      modal
      closeOnDocumentClick={closeOnDocumentClick}
      lockScroll
      contentStyle={contentStyle}
      className={className}
    >
      {close => {
        return (
          <div className="modal">
            <LinkButton className="close" onClick={close}>
              {closeButtonArrow ? (
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </LinkButton>
            {header && <div className="header">{header}</div>}
            <div className="content">
              {typeof children === 'function' ? children(close) : children}
            </div>
          </div>
        );
      }}
    </Popup>
  );
};

export default Modal;
