import * as types from 'actions/types';
import { createReducer, successType } from 'lib/callAPI';
import { combineReducers } from 'redux';

const pageCount = (state = '', action) => {
  switch (action.type) {
    case successType(types.SEARCH_VACANCY_LIST):
      return action.response.pageCount;
    default:
      return state;
  }
};

const byPage = (state = {}, action) => {
  switch (action.type) {
    case successType(types.SEARCH_VACANCY_LIST):
      return {
        ...state,
        [action.response.pageIndex]: [...action.response.data]
      };
    default:
      return state;
  }
};

const totalCount = (state = '', action) => {
  switch (action.type) {
    case successType(types.SEARCH_VACANCY_LIST):
      return action.response.totalCount;
    default:
      return state;
  }
};

const byCriteria = (state = {}, action) => {
  switch (action.type) {
    case successType(types.SEARCH_VACANCY_LIST):
      const prev = state[getKey(action.response.criteria)];
      return {
        ...state,
        [getKey(action.response.criteria)]: {
          pageCount: pageCount(prev && prev.pageCount, action),
          byPage: byPage(prev && prev.byPage, action),
          totalCount: totalCount(prev && prev.totalCount, action)
        }
      };
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case successType(types.GET_VACANCY):
      return {
        ...state,
        [action.response.pk]: { ...action.response }
      };
    default:
      return state;
  }
};

const dataReducer = combineReducers({
  byId,
  byCriteria
});

export default createReducer(
  [types.SEARCH_VACANCY_LIST, types.GET_VACANCY],
  dataReducer
);

const getKey = criteria => {
  return JSON.stringify(criteria);
};

export const getItems = (data, criteria, page) => {
  return (
    data.byCriteria[getKey(criteria)] &&
    data.byCriteria[getKey(criteria)].byPage[page]
  );
};

export const getPageCount = (data, criteria) => {
  return (
    data.byCriteria[getKey(criteria)] &&
    data.byCriteria[getKey(criteria)].pageCount
  );
};

export const getTotalCount = (data, criteria) => {
  return (
    data.byCriteria[getKey(criteria)] &&
    data.byCriteria[getKey(criteria)].totalCount
  );
};
