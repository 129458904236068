import React from 'react';
import PropTypes from 'prop-types';
import style from './Title.module.css';

const Title = ({ vacancy }) => {
  return <h3 className={style['title']}>{vacancy.title}</h3>;
};

Title.propTypes = {
  vacancy: PropTypes.object.isRequired
};

export default Title;
