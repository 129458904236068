import React from 'react';
import PropTypes from 'prop-types';
import style from './Map.module.css';
import { Translate } from 'components/Localize';
import { Kind } from 'components/Format';
import { Link } from 'react-router-dom';

const MarketTooltip = ({ logo, title, company, href, kind }) => {
  return (
    <div className={style['tooltip']}>
      <img className={style['tooltip-logo']} src={logo} alt="" />
      <Link to={href}>
        <div className={style['tooltip-title']}>{title}</div>
      </Link>
      <span className={style['tooltip-badge']}>
        <Kind name={kind} />
      </span>
      <span className={style['tooltip-company']}>
        <Translate id="at" /> {company}
      </span>
    </div>
  );
};

MarketTooltip.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired
};

export default MarketTooltip;
