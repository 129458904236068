import React from 'react';
import cx from 'classnames';
import style from './Section.module.css';

const Section = ({ children, className, noPadding }) => {
  return (
    <div
      className={cx(
        'container',
        style['section'],
        { [style['no-padding']]: noPadding },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Section;
