import * as types from './types';
import brandingApi from 'api/BrandingApi';
import { createAction } from 'lib/callAPI';

export function getCompany(alias) {
  return createAction({
    type: types.GET_COMPANY,
    callAPI: () => brandingApi.getCompanyBranding(alias),
    shouldCallAPI: state => !state.company.data
  });
}

export function refetchCompany(alias) {
  return createAction({
    type: types.GET_COMPANY,
    callAPI: () => brandingApi.getCompanyBranding(alias),
    shouldCallAPI: () => alias
  });
}
