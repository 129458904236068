import React, { useState } from 'react';
import Collapse from 'components/Collapse';
import cx from 'classnames';
import Button from 'components/Button';
import { Translate } from 'components/Localize';
import { Error } from 'components/Alert';
import helpRequestApi from 'api/HelpRequestApi';
import { Form, InputField, PhoneInput } from 'components/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getLegalUrl } from 'lib/landing';
import styles from '../../LeftSideFilter.module.css';

const Sms = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [counter, setCounter] = useState(0);

  const renderContent = () => {
    const handleSubmit = (values, { setErrors, resetForm }) => {
      return helpRequestApi
        .sendHelpRequest({
          ...values,
          kind: 'sms'
        })
        .then(() => {
          resetForm();
          setIsSubmitted(true);
          setCounter(0);
          const interval = setInterval(() => {
            setCounter(counter => {
              if (counter === 3) {
                clearInterval(interval);
                setIsSubmitted(false);
              }
              return counter + 1;
            });
          }, 1000);
        })
        .catch(error => {
          if (error.detail) {
            setErrors({ _error: error.detail });
          } else {
            return Promise.reject(error);
          }
        });
    };

    return (
      <Form
        initialValues={{
          phone_number: ''
        }}
        validateSchema={{
          phone_number: { required: true, phone: true }
        }}
        onSubmitCustom={handleSubmit}
      >
        {props => (
          <Translate>
            {({ translate, activeLanguage }) => (
              <div>
                {isSubmitted ? (
                  <div className={styles['collapse-content']}>
                    <div className={styles['multichannel-content-header']}>
                      <div>{translate('hey-thank-you')}</div>
                      <div>{translate('we-will-get-touch-you')}</div>
                    </div>
                    <div className={styles['countdown']}>
                      <div className={styles['weiter']}>
                        {translate('continue-in')}
                      </div>
                      <div className={styles['countdown-timer']}>
                        {3 - counter}
                      </div>
                    </div>
                  </div>
                ) : (
                  <form
                    onSubmit={props.handleSubmit}
                    className={styles['collapse-content']}
                  >
                    <div className={styles['multichannel-content-header']}>
                      <div>{translate('please-give-your')}</div>
                      <div>{translate('phone-number')}</div>
                    </div>
                    <Error>{props.errors._error}</Error>

                    <div
                      className={cx(
                        styles['collapse-input-field'],
                        'text-field'
                      )}
                    >
                      <InputField
                        name="phone_number"
                        rounded
                        placeholder={translate('phone')}
                        component={PhoneInput}
                        whiteError
                        className={styles['collapse-input']}
                      />
                    </div>
                    <Button
                      type="submit"
                      className={cx(
                        styles['collapse-submit-button'],
                        styles['sms-button'],
                        'button button-search'
                      )}
                      loading={props.isSubmitting}
                    >
                      <div className={styles['submit-button']}>
                        <div>{translate('remind')}</div>
                        <FontAwesomeIcon
                          className={styles['collapse-button']}
                          icon={faArrowRight}
                        />
                      </div>
                    </Button>
                    <div className={styles['multichannel-privacy-policy']}>
                      <a href={getLegalUrl(activeLanguage.code)}>
                        {translate('privacy-policy')}
                      </a>
                    </div>
                  </form>
                )}
              </div>
            )}
          </Translate>
        )}
      </Form>
    );
  };
  const getHeaderText = isOpen => {
    return (
      <div className={styles['collapse-header']}>
        <img
          className={styles['collapse-header-img']}
          src="https://dev.jobufo.com/media/tmp/frontend-additions/Reminder/images/sms.svg"
          alt="SMS"
        />
        {isOpen ? (
          <FontAwesomeIcon
            className={cx(styles['collapse-button'], styles['white'])}
            icon={faArrowLeft}
          />
        ) : (
          <FontAwesomeIcon
            className={cx(styles['collapse-button'], styles['white'])}
            icon={faArrowRight}
          />
        )}
      </div>
    );
  };
  return (
    <div className={cx(styles['multichannel-collapse'], styles['sms-channel'])}>
      <Collapse trigger={getHeaderText}>{renderContent()}</Collapse>
    </div>
  );
};

export default Sms;
