import React from 'react';
import PropTypes from 'prop-types';
import { Collapse as BaseCollapse } from 'react-collapse';

class Collapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.defaultValue
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ isOpen: this.props.value });
    }
  }

  handleClick = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  renderTrigger() {
    const { trigger } = this.props;
    const triggerProps = { onClick: this.handleClick };

    if (typeof trigger === 'function')
      return React.cloneElement(trigger(this.state.isOpen), triggerProps);

    return React.cloneElement(trigger, triggerProps);
  }

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        {this.renderTrigger()}
        <BaseCollapse isOpened={isOpen}>{isOpen && children}</BaseCollapse>
      </React.Fragment>
    );
  }
}

Collapse.propTypes = {
  trigger: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.func.isRequired
  ]),
  defaultValue: PropTypes.bool,
  value: PropTypes.bool
};

Collapse.defaultProps = {
  defaultValue: false,
  value: false
};

export default Collapse;
