import React, { useState } from 'react';
import cx from 'classnames';
import style from './BlurEffect.module.css';

const BlurContext = React.createContext();

const BlurProvider = ({ children }) => {
  const [isBlurred, toggleBlur] = useState(false);
  return (
    <BlurContext.Provider
      value={{
        isBlurred,
        className: cx({ [style['is-blurred']]: isBlurred }),
        toggleBlur: open => {
          //in order not to update BlurEffect is state is not changed
          if (open !== isBlurred) {
            toggleBlur(open);
          }
        }
      }}
    >
      {children}
    </BlurContext.Provider>
  );
};

const BlurEffect = ({ children }) => {
  return (
    <BlurContext.Consumer>{context => children(context)}</BlurContext.Consumer>
  );
};

export { BlurProvider, BlurContext };
export default BlurEffect;
