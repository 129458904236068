import React from 'react';
import TagDropDown from './TagDropDown';
import withSearchState from 'app/withSearchState';

const Kind = ({ getUriState, pushUriStateWithoutPage }) => {
  const { tag } = getUriState();
  const handleChange = e => {
    pushUriStateWithoutPage({ tag: e.target.value });
  };
  return <TagDropDown value={tag} onChange={handleChange} />;
};

export default withSearchState(Kind);
