import ServerApi from './ServerApi';
import { getDefaultLanguage } from 'lib/languages';
import { getLogoutUrl } from 'lib/landing';

class AuthApi extends ServerApi {
  login(email, password) {
    return super.post('/auth/login/', {
      email,
      password
    });
  }
  callBack(first_name, phone_number) {
    return super.post('/auth/callback/', {
      first_name,
      phone_number
    });
  }

  googleLogin(access_token) {
    return super.post('/v1.3/auth/google/', { access_token });
  }

  facebookLogin(access_token) {
    return super.post('/v1.3/auth/facebook/', { access_token });
  }

  getAuthUser() {
    return super.get('/v1.3/auth/user/');
  }

  logout() {
    const iframe = document.createElement('iframe');
    iframe.name = 'hidden-form';
    iframe.id = 'hidden-form';
    iframe.style = 'display:none';
    const form = document.createElement('form');
    form.method = 'get';
    form.action = getLogoutUrl(getDefaultLanguage());
    form.target = 'hidden-form';
    document.body.appendChild(form);
    form.parentNode.insertBefore(iframe, form);
    form.submit();
    return super.post('/auth/logout/', undefined);
  }

  register({
    first_name,
    last_name,
    email,
    password,
    birthday,
    gender,
    phone_number,
    city,
    legal_confirmed
  }) {
    return super.post('/users/signup/', {
      first_name,
      last_name,
      email,
      password,
      birthday,
      gender,
      phone_number,
      city,
      legal_confirmed
    });
  }

  verify(phone) {
    return super.post('/v1.3/users/validate/sms/send/', {
      phone_number: phone
    });
  }

  validate(phone, code) {
    return super.post('/v1.3/users/validate/sms/confirm/', {
      phone_number: phone,
      code
    });
  }

  submitPassword(password, company) {
    return super.post('/v1/bva-auth/validate-password/', {
      password,
      company
    });
  }

  validatePasswordToken(token, company) {
    //return new Promise(resolve => setTimeout(() => resolve(), 10000));
    //return Promise.reject({});
    return super.post('/v1/bva-auth/validate-password/', {
      key: token,
      company
    });
  }
}

export default new AuthApi();
