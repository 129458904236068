import ServerApi from './ServerApi';
import { getDefaultLanguage } from 'lib/languages';
import { parse } from 'qs';

class BrandingApi extends ServerApi {
  getCompanyBranding(alias) {
    const { referrer } = parse(window.location.search.slice(1));
    const referrerPart = referrer ? `&referrer=${referrer}` : '';
    const aliasPart = alias ? alias.toLowerCase() : '';
    return super.get(
      `/v1/recruiting/companybranding/${aliasPart}/?language=${getDefaultLanguage()}${referrerPart}`
    );
  }
}

export default new BrandingApi();
