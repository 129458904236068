import React from 'react';
import PropTypes from 'prop-types';
import { Time } from 'components/Format';
import { Translate } from 'components/Localize';
import Tooltip from 'components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import style from './Applied.module.css';

const Applied = ({ vacancy, children }) => {
  if (vacancy.applied) {
    return (
      <Tooltip
        message={
          <span>
            <Translate id="already-applied" /> <Time date={vacancy.applied} />
          </span>
        }
      >
        <span>
          <FontAwesomeIcon
            icon={faCalendarCheck}
            className={style['applied']}
          />
        </span>
      </Tooltip>
    );
  }
  return children;
};

Applied.propTypes = {
  vacancy: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default Applied;
