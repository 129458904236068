import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import style from './DropDown.module.css';
import LinkButton from 'components/LinkButton';
import { Mobile } from 'components/MediaQuery';
import { Translate } from 'components/Localize';
import { BlurContext } from 'components/BlurEffect';
import ModalFilter from 'components/ModalFilter';

// import addImage from 'styles/img/add.png';
// import clearImage from 'styles/img/clear.png';
// import Modal from '../Modal';

class DropDown extends React.Component {
  state = {
    open: false,
    mounted: true
  };

  static contextType = BlurContext;

  handleClose = e => {
    this.setState({
      open: false
    });
  };

  handleMouseDown = event => {
    const order = window.getComputedStyle(this.containerRef).order;
    if (!this.state.open) {
      this.containerRef.style.order = order;
    }

    if (event.type === 'mousedown' && event.button !== 0) return;
    event.stopPropagation();
    event.preventDefault();

    if (this.state.open) {
      this.fireOnApply();
      this.context.toggleBlur(false);
    }
    this.context.toggleBlur(true);
    this.setState(({ open }) => ({ open: !open }));
  };

  handleClearClick = async e => {
    this.context.toggleBlur(false);
    this.setState(({ open }) => ({ open: !open }));
    (await this.props.onClear) && this.props.onClear(e);
    this.fireOnApply();
  };

  fireOnApply = () => {
    // this.context.toggleBlur(!this.state.open);
    this.props.onApply && this.props.onApply();
  };

  handleDocumentClick = event => {
    const ref = this.containerRef;
    setTimeout(() => {}, 0);

    if (window.innerWidth > 768) {
      if (this.state.mounted) {
        if (ref && !ref.contains(event.target)) {
          if (this.state.open) {
            this.setState({ open: false }, () => {
              if (!ref.parentElement.querySelector('.is-open')) {
                this.context.toggleBlur(false);
              }
            });
            this.fireOnApply();
          }
        }
      }
    }
  };

  componentDidMount = () => {
    document.addEventListener('click', this.handleDocumentClick, false);
    document.addEventListener('resize', this.handleDocumentClick, false);
    document.addEventListener('touchend', this.handleDocumentClick, false);
  };

  componentWillUnmount = () => {
    this.setState({
      mounted: false
    });
    document.removeEventListener('click', this.handleDocumentClick, false);
    document.removeEventListener('resize', this.handleDocumentClick, false);
    document.removeEventListener('touchend', this.handleDocumentClick, false);
  };

  render() {
    const {
      header,
      children,
      active,
      className,
      containerClassName
    } = this.props;

    const { open } = this.state;

    const renderContainer = () => (
      <div className={style['dropdown-wrapper']}>
        <div className={cx(style['dropdown-container'], containerClassName)}>
          {children}
        </div>
        <div className={style['dropdown-container-buttons']}>
          <LinkButton
            onClick={this.handleClearClick}
            className={cx(style['dropdown-container-button'], style['apply'])}
          >
            {/*<img src={clearImage} alt="clearImage" />*/}
            <Translate id="clear" />
          </LinkButton>
          <LinkButton
            className={cx(style['dropdown-container-button'], style['apply'])}
            onClick={this.handleMouseDown}
          >
            {/*<img src={addImage} alt="addImage" />*/}
            <Translate id="apply-filter" />
            <Mobile>{header}</Mobile>
          </LinkButton>
        </div>
      </div>
    );
    return (
      <div
        ref={node => (this.containerRef = node)}
        className={cx(
          style['dropdown'],
          { 'dropdown-position': active },
          { 'is-open': open },
          className
        )}
      >
        <div
          className={cx(style['dropdown-control'], {
            [style['active']]: open || active
          })}
          onClick={this.handleMouseDown}
          // onTouchEnd={this.handleMouseDown}
        >
          <div className={style['dropdown-header']}>{header}</div>
          <i className={cx(style['dropdown-arrow'], 'icon icon-small-down')} />
        </div>
        {open && (
          <Mobile>
            {matches =>
              matches
                ? ReactDOM.createPortal(
                    <ModalFilter
                      header={header}
                      handleStop={this.handleStop}
                      handleClose={this.handleClose}
                    >
                      {renderContainer()}
                    </ModalFilter>,
                    document.querySelector('#root')
                  )
                : renderContainer()
            }
          </Mobile>
        )}
      </div>
    );
  }
}

DropDown.propTypes = {
  header: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  showClear: PropTypes.bool,
  onClear: PropTypes.func,
  onApply: PropTypes.func,
  active: PropTypes.bool
};

DropDown.defaultProps = {
  active: false,
  showClear: true
};

export default DropDown;
