import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { Translate } from 'components/Localize';
import { Form, InputField } from 'components/Form';
import Dialog from 'components/Dialog';
import { Error } from 'components/Alert';
import { submitPassword } from 'actions/validatePasswordActions';
import { connect } from 'react-redux';
import './PasswordForm.css';

const PasswordForm = ({ company, submitPassword }) => {
  const handleSubmit = ({ password }, { setErrors }) => {
    return submitPassword(password, company).then(({ error }) => {
      if (error) {
        if (error.is_valid !== undefined && !error.is_valid) {
          setErrors({ password: 'Invalid password' });
        } else {
          return Promise.reject(error);
        }
      }
    });
  };

  return (
    <div className="password-form-container">
      <div className="password-form">
        <Dialog>
          <Form
            initialValues={{
              password: ''
            }}
            validateSchema={{
              password: { required: true }
            }}
            onSubmitCustom={handleSubmit}
          >
            {props => (
              <Translate>
                {({ translate }) => (
                  <form onSubmit={props.handleSubmit}>
                    <h4>{translate('protected-content')}</h4>
                    <Error>{props.errors._error}</Error>
                    <br />
                    <div className="text-field text-field__material">
                      <InputField
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        rounded
                        placeholder={translate('password')}
                      />
                    </div>
                    <Button
                      type="submit"
                      className="button button--full password-form-btn"
                      disabled={!props.isValid}
                      loading={props.isSubmitting}
                    >
                      {translate('submit')}
                    </Button>
                  </form>
                )}
              </Translate>
            )}
          </Form>
        </Dialog>
      </div>
    </div>
  );
};

PasswordForm.propTypes = {
  company: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default connect(
  undefined,
  { submitPassword }
)(PasswordForm);
