import ServerApi from './ServerApi';

class UserApi extends ServerApi {
  updateUser(
    id,
    {
      first_name,
      last_name,
      phone,
      street,
      houseNumber,
      city,
      birthday,
      gender
    }
  ) {
    return super.patch(`/users/ufouser/${id}/`, {
      user: {
        first_name,
        last_name
      },
      phone: {
        number: phone
      },
      address: {
        street,
        housenumber: houseNumber,
        city: {
          name: city
        }
      },
      birthday,
      gender
    });
  }

  updatePhone(userId, phone) {
    return super.patch(`/users/ufouser/${userId}/`, {
      phone: {
        number: phone
      }
    });
  }
}

export default new UserApi();
