import React from 'react';
import PropTypes from 'prop-types';
import logo from 'styles/img/Logo.svg';
import logo2 from 'styles/img/Logo2.svg';
import Branding from 'components/Branding';
import Profile from 'app/Layout/Profile';
import { Translate } from 'components/Localize';
import { getMainUrl } from 'lib/landing';

class Common extends React.Component {
  state = {
    searchIsFocus: false
  };
  handleChangeLogo = () => {
    this.setState({ searchIsFocus: !this.state.searchIsFocus });
  };
  extendComponentWithNewProps = (Component, newProps) => {
    if (Component) {
      return React.cloneElement(Component, {
        ...Component.props,
        ...newProps
      });
    }
    return null;
  };
  render() {
    const { children, header, citySearch } = this.props;
    const ExtendedHeader = this.extendComponentWithNewProps(header, {
      handleChangeLogo: this.handleChangeLogo
    });

    const CithSearch = this.extendComponentWithNewProps(citySearch, {
      handleChangeLogo: this.handleChangeLogo
    });

    return (
      <Branding>
        {resolve => (
          <React.Fragment>
            <div className="header__nav">
              <div className="header__inner">
                <div className="header__balance">
                  <Translate>
                    {({ activeLanguage }) => (
                      <a
                        className={`logo ${
                          this.state.searchIsFocus ? 'logo-null' : ''
                        }`}
                        href={
                          resolve('header.logo_link') ||
                          getMainUrl(activeLanguage.code)
                        }
                      >
                        <img
                          alt="logo"
                          className="logo-main"
                          src={resolve('header.partner_logo') || logo}
                        />
                        <img
                          alt="logo"
                          className="logo-search"
                          src={resolve('header.partner_logo') || logo2}
                        />
                      </a>
                    )}
                  </Translate>
                  <div className="header__center">{ExtendedHeader}</div>
                  <div className="header__center">{CithSearch}</div>
                </div>
                <div className="header__links">
                  <Profile />
                </div>
              </div>
            </div>
            <div className="header__content">
              <div className="header__wrapper">
                <div className="header__box">{children}</div>
              </div>
            </div>
          </React.Fragment>
        )}
      </Branding>
    );
  }
}

Common.propTypes = {
  // children: PropTypes.node.isRequired,
  header: PropTypes.node,
  noPadding: PropTypes.bool
};

export default Common;
