import * as types from './types';
import geoApi from 'api/GeoApi';
import { createAction } from 'lib/callAPI';

export function getCountries() {
  return createAction({
    type: types.GET_COUNTIES,
    callAPI: () => geoApi.getCountries(),
    shouldCallAPI: state => !state.countries.data
  });
}
