import React from 'react';
import { Translate } from 'components/Localize';
import FooterLinks from './FooterLinks';
import Section from 'components/Section';
import Branding from 'components/Branding';
import { getMainUrl } from 'lib/landing';
import CustomFooter from './CustomFooter';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <Branding>
      {(resolve, resolveCompany) => {
        const customFooterUrl = resolve('body.custom_footer_code');
        if (customFooterUrl) {
          return <CustomFooter htmlUrl={customFooterUrl} />;
        }
        return (
          <footer className={styles.footer}>
            <Section>
              <FooterLinks />
            </Section>
            <div className={styles.footer__copyright}>
              <div>
                <Translate
                  id="copyright"
                  data={{ year: new Date().getFullYear() }}
                />
              </div>
              {resolveCompany('gdpr_link') && (
                <span>
                  <Translate
                    id="terms-company"
                    data={{
                      link_start: `<a target="_blank"  rel="noopener noreferrer" href="${resolveCompany(
                        'gdpr_link'
                      )}">`,
                      company: resolveCompany('name'),
                      link_end: `</a>`
                    }}
                    options={{ renderInnerHtml: true }}
                  />
                </span>
              )}
              <span>
                <Translate id="powered-by" />{' '}
                <Translate>
                  {({ activeLanguage }) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={getMainUrl(activeLanguage.code)}
                    >
                      JobUFO
                    </a>
                  )}
                </Translate>
              </span>
            </div>
          </footer>
        );
      }}
    </Branding>
  );
};

Footer.propTypes = {
  //prop: PropTypes.array.isRequired
};

export default Footer;
