const KEY = 'passwordToken';

class PasswordTokenStorage {
  static isExist() {
    return !!this.get();
  }

  static get() {
    return localStorage.getItem(KEY);
  }

  static set(passwordToken) {
    localStorage.setItem(KEY, passwordToken);
  }

  static remove() {
    localStorage.removeItem(KEY);
  }
}

export default PasswordTokenStorage;
