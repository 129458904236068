import React from 'react';
import InputFeedbackWhite from '../InputFeedbackWhite';
import './GroupInput.css';

const GroupInputWithWhiteError = ({
  innerComponent: Component,
  form,
  field,
  isErrorMessage = true,
  ...rest
}) => {
  const { errors, touched } = form;
  const error = touched[field.name] && errors[field.name];
  return (
    <React.Fragment>
      {/*<div className="group-input">*/}
      <Component
        className={error ? 'invalid' : ''}
        {...field}
        {...rest}
        meta={form}
      />
      {isErrorMessage ? <InputFeedbackWhite error={error} /> : null}
      {/*</div>*/}
    </React.Fragment>
  );
};

export default GroupInputWithWhiteError;
