import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import { Error } from 'components/Alert';
import Checkbox from 'components/Checkbox';
import Collapse from 'components/Collapse';
import { getTagList } from 'actions/tagActions';
import { connect } from 'react-redux';
import { Translate } from 'components/Localize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import styles from '../../LeftSideFilter.module.css';

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedTags: props.value
    };
  }

  componentDidMount() {
    this.props.getTagList(this.props.companyId, this.props.partner);
  }

  componentDidUpdate(prevProps) {
    //compare array
    if (this.props.value.join('') !== prevProps.value.join('')) {
      this.setState({
        checkedTags: this.props.value
      });
    }
  }

  handleChangeFor = (e, name) => {
    const { checkedTags } = this.state;
    const { onChange } = this.props;
    let newState;
    if (e.target.checked) {
      newState = [...checkedTags, name];
    } else {
      newState = checkedTags.filter(tag => tag !== name);
    }
    this.setState({
      checkedTags: newState
    });

    onChange({
      target: { name, value: newState },
      persist: () => {}
    });
  };

  renderContent() {
    const { loading, data, error } = this.props;
    const { checkedTags } = this.state;
    if (error) {
      return (
        <Error
          general
          retry={() => this.props.getTagList(this.props.companyId)}
        />
      );
    }
    if (loading) {
      return <Loader />;
    }
    return (
      <div className={styles['collapse-content']}>
        {data.map(tag => (
          <Checkbox
            key={`tag_list${tag.slug}`}
            value={checkedTags.includes(tag.slug)}
            onChange={e => this.handleChangeFor(e, tag.slug)}
            size="small"
          >
            {tag.name}
          </Checkbox>
        ))}
      </div>
    );
  }

  getHeaderText = isOpen => {
    const { checkedTags } = this.state;
    const count = checkedTags.length;
    const isAllChecked = checkedTags.length === 0;
    return (
      <div className={styles['collapse-header']}>
        <div>
          <Translate id="industry" />
          {count > 0 && !isAllChecked && ` · ${count}`}
        </div>
        {isOpen ? (
          <FontAwesomeIcon
            className={styles['collapse-button']}
            icon={faMinus}
          />
        ) : (
          <FontAwesomeIcon
            className={styles['collapse-button']}
            icon={faPlus}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <Collapse defaultValue={true} trigger={this.getHeaderText}>
        {this.renderContent()}
      </Collapse>
    );
  }
}

TagList.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = ({ company, tag: { data, error, loading } }) => ({
  companyId: company.data && company.data.company && company.data.company.pk,
  partner: company.data && company.data.partner,
  loading: !data || loading,
  error: error,
  data: data || []
});

export default connect(
  mapStateToProps,
  { getTagList }
)(TagList);
