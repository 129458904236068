import * as types from './types';
import fileApi from 'api/FileApi';
import { createAction } from 'lib/callAPI';

export function getFooterHtml(url) {
  return createAction({
    type: types.GET_FOOTER_HTML,
    callAPI: () => fileApi.getFooterHtml(url),
    shouldCallAPI: state => !state.footerHtml.data
  });
}
