import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components/Localize';
import { Error } from 'components/Alert';
import Loader from 'components/Loader';
import Vacancy from '../Vacancy';
import Pagination from 'components/Pagination';
import { connect } from 'react-redux';
import { getRelated } from 'actions/vacancyActions';
import style from './Details.module.css';

class RelatedJobs extends React.Component {
  state = {
    pageIndex: 0
  };

  handlePageChange = pageIndex => {
    const { getRelated, companyId, vacancyId } = this.props;
    getRelated(companyId, vacancyId, pageIndex).then(() =>
      this.setState({ pageIndex })
    );
  };

  componentDidMount() {
    const { getRelated, companyId, vacancyId } = this.props;
    getRelated(companyId, vacancyId, this.state.pageIndex);
    // window.scrollTo(0, 0);
  }

  getItems() {
    const { pageIndex } = this.state;
    const { data } = this.props;
    return data && data.byPage[pageIndex];
  }

  renderItems() {
    const { pageIndex } = this.state;
    const { pageCount } = this.props;
    const items = this.getItems();
    if (!items) return null;

    return (
      <React.Fragment>
        {items.map(vacancy => <Vacancy key={vacancy.pk} data={vacancy} />)}
        {pageCount > 1 && (
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            onChange={this.handlePageChange}
          />
        )}
      </React.Fragment>
    );
  }

  renderInner() {
    const { error, loading } = this.props;
    if (error) {
      return <Error general />;
    }
    return <Loader loading={loading}>{this.renderItems()}</Loader>;
  }

  render() {
    const items = this.getItems();
    if (items && items.length === 0) {
      return null;
    }
    return (
      <div className={style['vacancy-body-block']}>
        <h3 className={style['vacancy-body-block-title']}>
          <Translate id="these-jobs-could-also-suit-you" />
        </h3>
        {this.renderInner()}
      </div>
    );
  }
}

RelatedJobs.propTypes = {
  companyId: PropTypes.number.isRequired,
  vacancyId: PropTypes.number.isRequired
};

const mapStateToProps = (
  { relatedVacancies: { data, loading, error } },
  { vacancyId }
) => {
  const idData = data.byId[vacancyId];
  return {
    data: idData,
    pageCount: idData && idData.pageCount,
    loading: loading,
    error
  };
};

export default connect(
  mapStateToProps,
  { getRelated }
)(RelatedJobs);
