import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import TextInput from '../TextInput';
import styles from './SearchInput.module.css';

const SearchInput = ({ className, value, onChange, ...props }) => (
  <div className={cx(styles['search-input-container'], className)}>
    <TextInput
      rounded
      value={value}
      onChange={onChange}
      className={styles['search-input']}
      {...props}
    />
    <FontAwesomeIcon
      icon={faSearch}
      className={cx(styles['search-input-icon'])}
    />
    <FontAwesomeIcon
      icon={faTimesCircle}
      className={cx(styles['search-input-remove'], {
        [styles.hidden]: value === '' || value === undefined || value === null
      })}
      onClick={() => onChange({ target: { value: '' } })}
    />
  </div>
);

SearchInput.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SearchInput;
