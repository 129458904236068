import * as types from './types';
import applicationApi from 'api/ApplicationApi';
import { createAction } from 'lib/callAPI';

export function getApplicationStatuses() {
  return createAction({
    type: types.GET_APPLICATION_STATUSES,
    callAPI: () => applicationApi.getStatuses(),
    shouldCallAPI: state => !state.applicationStatuses.data
  });
}

export function refetchApplicationStatuses() {
  return createAction({
    type: types.GET_APPLICATION_STATUSES,
    callAPI: () => applicationApi.getStatuses(),
    //call if data is exist, if not getApplicationStatuses will be called on demand.
    shouldCallAPI: state => state.applicationStatuses.data
  });
}
