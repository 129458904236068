import React from 'react';
import CityTextInput from './CityTextInput';
import withSearchState from 'app/withSearchState';

const HeaderCity = ({
  getUriState,
  pushUriStateWithoutPage,
  isIconPath,
  handleChangeLogo
}) => {
  const { city } = getUriState();
  const handleChange = e => {
    if (city !== e.target.value) {
      pushUriStateWithoutPage({
        city: e.target.value === '' ? undefined : e.target.value
      });
    }
  };
  //set key depend in city in order to component be updated
  return (
    <CityTextInput
      key={city}
      value={city}
      onChange={handleChange}
      isIconPath={isIconPath}
      handleChangeLogo={handleChangeLogo}
    />
  );
};

export default withSearchState(HeaderCity);
