import React from 'react';
import { connect } from 'react-redux';
import { validatePasswordToken } from 'actions/validatePasswordActions';
import PasswordForm from './PasswordForm';

class PasswordProtection extends React.Component {
  componentDidMount() {
    if (this.props.isEnabled) {
      this.props.validatePasswordToken(this.props.company);
    }
  }

  render() {
    const {
      company,
      children,
      isEnabled,
      isValid,
      validating,
      error
    } = this.props;
    if (isEnabled) {
      if (validating) {
        //show loading indicator
        return null;
      }
      if (error) {
        return <div>Failed to validate password token</div>;
      }
      if (!isValid) {
        return <PasswordForm company={company} />;
      }
    }
    return children;
  }
}

const mapStateToProps = ({ company: { data }, validatePassword }) => ({
  isEnabled: data && data.company && data.company.password_enabled,
  company: data && data.company && data.company.pk,
  partner: data && data.partner,
  isValid: validatePassword.data && validatePassword.data.is_valid,
  validating: validatePassword.loading,
  error: validatePassword.error && validatePassword.error.status !== 403
});

export default connect(
  mapStateToProps,
  { validatePasswordToken }
)(PasswordProtection);
