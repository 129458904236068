import * as types from 'actions/types';
import { createReducer, successType } from 'lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.SUBMIT_PASSWORD):
      return { ...action.response };
    case successType(types.VALIDATE_PASSWORD_TOKEN):
      return { ...action.response };
    default:
      return state;
  }
};

export default createReducer(types.VALIDATE_PASSWORD_TOKEN, dataReducer);
