import React from 'react';
import { Route } from 'react-router-dom';

const CompanyContext = React.createContext();

const getAlias = match => {
  return match.params.alias;
};

const resolveFor = match => path => {
  const alias = match.params.alias;
  let newPath = path;
  if (alias) {
    newPath = `/${alias}` + newPath;
  }
  return newPath;
};

const CompanyProvider = ({ children }) => {
  //alias should be more than 2 symbols (or any digital identifier) otherwise /s with be used.
  return (
    <Route path="/:alias([a-z0-9-]{2,}|[0-9])?">
      {({ match }) => (
        <CompanyContext.Provider
          value={{
            alias: getAlias(match),
            resolve: resolveFor(match)
          }}
        >
          {children({
            alias: getAlias(match),
            resolve: resolveFor(match)
          })}
        </CompanyContext.Provider>
      )}
    </Route>
  );
};

const CompanyResolver = ({ children }) => {
  return (
    <CompanyContext.Consumer>
      {context => children(context)}
    </CompanyContext.Consumer>
  );
};

export { CompanyResolver };
export default CompanyProvider;
