import ServerApi from './ServerApi';

class HelpRequestApi extends ServerApi {
  sendHelpRequest(value) {
    if (value.kind === 'discord' || value.kind === 'email') {
      return super.post('/support/helprequest/', {
        kind: value.kind,
        username: value.username
      });
    }
    return super.post('/support/helprequest/', {
      kind: value.kind,
      phone_number: value.phone_number
    });
  }
}

export default new HelpRequestApi();
