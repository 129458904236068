import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from 'components/Tooltip';
import style from './Marker.module.css';

const MarkerInner = React.forwardRef(
  ({ icon, label, primary = false, multi = false }, ref) => (
    <div
      className={cx(
        style['marker'],
        { [style['primary']]: primary },
        { [style['multi']]: multi }
      )}
      ref={ref}
    >
      <div
        className={cx(style['circle'], { [style['label']]: label })}
        style={icon ? { backgroundImage: `url('${icon}')` } : {}}
      >
        {label}
      </div>
    </div>
  )
);

const Marker = ({ tooltip, ...props }) => {
  return (
    <Tooltip
      message={tooltip}
      theme="light"
      placement="bottom"
      on="click"
      className={style['tooltip']}
    >
      <MarkerInner {...props} />
    </Tooltip>
  );
};

Marker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  icon: PropTypes.string,
  tooltip: PropTypes.node,
  label: PropTypes.node,
  primary: PropTypes.bool,
  multi: PropTypes.bool
};

export default Marker;
