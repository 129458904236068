import cssVars from 'css-vars-ponyfill';

cssVars({
  watch: true
});

export const update = variables => {
  //filter out vars which is undefined in order not to override default.
  for (const property in variables) {
    if (variables[property] === undefined) {
      delete variables[property];
    }
  }
  cssVars({
    variables,
    watch: true
  });
};
