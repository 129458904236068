import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const JobPostingJSON = ({ vacancy }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
        {
          "@context" : "https://schema.org/",
          "@type" : "JobPosting",
          "title" : "<h1>${vacancy.title &&
            vacancy.title.replace(/"/g, "'")}</h1>",
          "description" : "${vacancy.description &&
            vacancy.description.replace(/"/g, "'")}",
          "datePosted" : "${vacancy.publication_date}",
          "validThrough" : "${new Date(
            new Date(vacancy.publication_date).setDate(
              new Date(vacancy.publication_date).getDate() +
                vacancy.publication_duration
            )
          ).toISOString()}",
          "employmentType" : "FULL_TIME",
          "hiringOrganization" : {
            "@type" : "Organization",
            "name" : "${vacancy.company &&
              vacancy.company.name &&
              vacancy.company.name.replace(/"/g, "'")}",
            "logo" : "${(vacancy.company && vacancy.company.logo) ||
              (vacancy.partner && vacancy.partner.logo)}"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${vacancy.company &&
                vacancy.company.address &&
                vacancy.company.address.street &&
                vacancy.company.address.street.replace(/"/g, "'")}",
              "addressLocality": "${vacancy.company &&
                vacancy.company.address &&
                vacancy.company.address.city &&
                vacancy.company.address.city.name &&
                vacancy.company.address.city.name.replace(/"/g, "'")}",
              "addressRegion": "${vacancy.company &&
                vacancy.company.address &&
                vacancy.company.address.city &&
                vacancy.company.address.city.state &&
                vacancy.company.address.city.state.name &&
                vacancy.company.address.city.state.name.replace(/"/g, "'")}",
              "postalCode": "${vacancy.company &&
                vacancy.company.address &&
                vacancy.company.address.postal_code &&
                vacancy.company.address.postal_code.replace(/"/g, "'")}",
              "addressCountry": "${vacancy.company &&
                vacancy.company.address &&
                vacancy.company.address.city &&
                vacancy.company.address.city.state &&
                vacancy.company.address.city.state.country &&
                vacancy.company.address.city.state.country.code &&
                vacancy.company.address.city.state.country.code.replace(
                  /"/g,
                  "'"
                )}"
            }
          }
        }
      `}
      </script>
    </Helmet>
  );
};

JobPostingJSON.propTypes = {
  vacancy: PropTypes.object.isRequired
};

export default JobPostingJSON;
