import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import { getDefaultLanguage, locales } from 'lib/languages';

const formats = {
  dateTime: 'MMMM D, YYYY, hh:mm a',
  date: 'MMMM D, YYYY'
};

const Time = ({ date, format = 'date' }) => {
  const dateTime = new Date(date);
  const lang = getDefaultLanguage();
  return (
    <time dateTime={dateTime.toISOString()}>
      {formatDate(dateTime, formats[format], { locale: locales[lang] })}
    </time>
  );
};

Time.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  format: PropTypes.oneOf(Object.keys(formats))
};

export default Time;
