import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CompanyResolver } from 'app/CompanyProvider';
import CitySelect from '../CitySelect';
import style from './CityTextInput.module.css';

class CityTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.value,
      suggestionSelected: false
    };
  }

  handleChange = e => {
    this.setState({
      selected: e.target.value
    });
  };

  handleKeyDown = e => {
    const { onChange } = this.props;
    const { selected, suggestionSelected } = this.state;
    if (e.keyCode === 13 && !suggestionSelected) {
      onChange({
        target: { value: selected },
        persist: () => {}
      });
    }
    this.setState({
      suggestionSelected: false
    });
  };

  handleSuggestionSelect = (value, suggestion) => {
    const { onChange } = this.props;
    this.setState({
      suggestionSelected: true
    });
    onChange({
      target: { value: value },
      persist: () => {}
    });
  };

  handleBlur = () => {
    const { onChange, handleChangeLogo } = this.props;
    const { selected } = this.state;
    onChange({
      target: { value: selected },
      persist: () => {}
    });
    handleChangeLogo();
  };

  render() {
    const { selected } = this.state;
    const { isIconPath, handleChangeLogo } = this.props;
    return (
      <CompanyResolver>
        {({ alias }) => (
          <CitySelect
            className={cx('search-box', style['city-text-input'])}
            isIconPath={isIconPath}
            iconClassName={style['icon-location']}
            value={selected}
            onChange={this.handleChange}
            onSuggestionSelect={this.handleSuggestionSelect}
            onBlur={this.handleBlur}
            onFocus={handleChangeLogo}
            companyId={alias}
            inputClassName="search-filter"
          />
        )}
      </CompanyResolver>
    );
  }
}

CityTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CityTextInput;
