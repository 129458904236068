import React, { useState } from 'react';
import styles from './AdBlockerDetector.module.css';
import { Translate } from 'components/Localize';
import AdBlockerImg from 'styles/images/ad-blocker.svg';
import RightArrow from 'styles/images/right-arrow.svg';
import AdBlockDetect from 'react-ad-block-detect';

export const AdBlockerDetector = ({ children }) => {
  const [showModal, handleModal] = useState(true);
  return (
    <div>
      {showModal && (
        <AdBlockDetect>
          <div
            onClick={() => handleModal(false)}
            className={styles['adp-underlay']}
          />
          <div className={styles['adp']}>
            <div className={styles['adp-img']}>
              <img alt="" src={AdBlockerImg} />
            </div>
            <h3>
              <Translate id="adblocker-header" />
            </h3>
            <p>
              <Translate id="adblocker-content" />
            </p>
            <button onClick={() => handleModal(false)}>
              <div>
                <Translate id="adblocker-button" />
              </div>
              <img alt="" src={RightArrow} />
            </button>
          </div>
        </AdBlockDetect>
      )}
      {children}
    </div>
  );
};
