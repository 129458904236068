import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import './Tooltip.css';

const Tooltip = ({
  message,
  children,
  onOpen,
  onHide,
  open,
  on = 'mouseenter focus',
  placement,
  theme = 'primary',
  className,
  interactiveBorder = 10
}) => {
  if (!message) {
    return children;
  }

  const renderChildren = () => {
    if (children.props && children.props.disabled) {
      // disabled buttons prevent the tooltip from showing
      return <div onClick={onOpen}>{children}</div>;
    }
    return React.cloneElement(children, { onClick: onOpen });
  };

  const props = {};
  if (onHide) {
    props.onHidden = onHide;
  }
  if (placement) {
    props.placement = placement;
  }

  return (
    <Tippy
      content={message}
      isVisible={open}
      arrow
      interactive
      interactiveBorder={interactiveBorder}
      theme={theme}
      trigger={on}
      className={className}
      {...props}
    >
      {renderChildren()}
    </Tippy>
  );
};

Tooltip.propTypes = {
  message: PropTypes.node,
  children: PropTypes.node.isRequired,
  on: PropTypes.string,
  className: PropTypes.string,
  interactiveBorder: PropTypes.number,
  open: PropTypes.bool,
  onHide: PropTypes.func,
  onOpen: PropTypes.func,
  theme: PropTypes.oneOf(['primary', 'light'])
};

export default Tooltip;
