import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import Search from 'styles/img/icons/search-icon.svg';
import { Translate } from 'components/Localize';
import Button from 'components/Button';
import VacancySelect, { SUGGESTION_TYPE } from 'app/Filter/VacancySelect';
import CitySelect from 'app/Filter/City/CitySelect';
import { Form, InputField } from 'components/Form';
import withSearchState from 'app/withSearchState';
import { CompanyResolver } from 'app/CompanyProvider';
import SelectKind from 'components/SelectKind';
import './FilterPanel.css';

const FilterPanel = ({
  getUriState,
  pushUriStateWithoutPage,
  location: { pathname }
}) => {
  const handleSubmit = ({ kind, text, location, tag }) => {
    pushUriStateWithoutPage(
      {
        kind,
        search: tag ? undefined : text,
        city: location || undefined,
        tag: tag ? tag : undefined
      },
      `${pathname}${pathname.endsWith('/') ? '' : '/'}s`
    );

    return Promise.resolve();
  };

  const handleSuggestionSelectFor = setFieldValue => (value, suggestion) => {
    if (suggestion.kind === SUGGESTION_TYPE.LOCATION) {
      setFieldValue('text', '');
      setFieldValue('location', value);
    }
    if (suggestion.kind === SUGGESTION_TYPE.INDUSTRY) {
      setFieldValue('tag', suggestion.filter_params.value);
    }
    if (suggestion.kind === SUGGESTION_TYPE.KIND) {
      setFieldValue('kind', suggestion.filter_params.value);
    }
  };

  const handleChangeFor = ({ setFieldValue, handleChange }) => e => {
    //reset tag if user type something after suggestion selected
    setFieldValue('tag', '');
    handleChange(e);
  };

  const { search, kind, city } = getUriState();
  return (
    <Form
      initialValues={{
        text: search,
        location: city,
        kind: kind,
        tag: undefined
      }}
      onSubmitCustom={handleSubmit}
    >
      {props => (
        <CompanyResolver>
          {({ alias }) => (
            <form onSubmit={props.handleSubmit}>
              <div className="text-field field-space">
                <span>
                  <Translate id="search" />
                </span>
                <InputField
                  name="text"
                  component={VacancySelect}
                  onChange={handleChangeFor(props)}
                  companyId={alias}
                  isErrorMessage={false}
                  onSuggestionSelect={handleSuggestionSelectFor(
                    props.setFieldValue
                  )}
                />
              </div>
              <div className="text-field field-space">
                <span>
                  <Translate id="location" />
                </span>

                <InputField
                  name="location"
                  isErrorMessage={false}
                  component={CitySelect}
                  companyId={alias}
                />
              </div>
              <div className="select-field field-space">
                <span>
                  <Translate id="kind" />
                </span>
                <InputField
                  name="kind"
                  component={SelectKind}
                  isErrorMessage={false}
                />
                {/*//////////////////////old/////////////////////////////*/}
                {/*<InputField name="kind" component={KindDropDown} />*/}
              </div>
              <div className="filter-panel-buttons">
                <Button
                  type="submit"
                  className="button button-search"
                  disabled={!props.isValid}
                  loading={props.isSubmitting}
                >
                  <span className="button-search__label">
                    <Translate id="search" />
                  </span>
                  <ReactSVG src={Search} />
                </Button>
              </div>
            </form>
          )}
        </CompanyResolver>
      )}
    </Form>
  );
};

export default compose(
  withSearchState,
  withRouter
)(FilterPanel);
