import React from 'react';
import cx from 'classnames';
import './TextInput.css';

const TextInput = React.forwardRef(
  ({ className, rounded = false, ...props }, ref) => {
    const classNames = {
      'form-input': true,
      'non-rounded': !rounded
    };
    return (
      <input
        type="text"
        className={cx(classNames, className)}
        ref={ref}
        {...props}
      />
    );
  }
);

export default TextInput;
