import * as types from './types';
import assistantApi from 'api/AssistantApi';
import { createAction } from 'lib/callAPI';

export function getAssistantList() {
  return createAction({
    type: types.GET_ASSISTANT_LIST,
    callAPI: () => assistantApi.getAssistantList(),
    shouldCallAPI: state => !state.assistants.data
  });
}

export function refetchAssistantList() {
  return createAction({
    type: types.GET_ASSISTANT_LIST,
    callAPI: () => assistantApi.getAssistantList()
  });
}
