import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Market from './Marker';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const style = [
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#ff0000'
      }
    ]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        hue: '#ff3200'
      },
      {
        visibility: 'on'
      }
    ]
  }
];
const Map = ({ location, address, children, icon }) => {
  const [point, setPoint] = useState(location);

  const handleApiLoaded = ({ map, maps }) => {
    if (!location) {
      const geocoder = new maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          const { lng, lat } = results[0].geometry.location;
          const location = {
            lng: typeof lng === 'function' ? lng() : lng,
            lat: typeof lat === 'function' ? lat() : lat
          };
          map.setCenter(location);
          setPoint(location);
        } else {
          console.error(
            'Geocode was not successful for the following reason: ' + status
          );
        }
      });
    } else {
      map.setCenter(location);
    }
  };

  const markers = children;
  if (point) {
    markers.push(
      <Market
        key="marker"
        lng={point.lng}
        lat={point.lat}
        icon={icon}
        primary
      />
    );
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        options={{
          styles: style
        }}
        bootstrapURLKeys={{ key: API_KEY }}
        //set default to Berlin
        defaultCenter={{ lat: 52.52, lng: 13.4 }}
        defaultZoom={11}
        //You can access to Google Maps map and maps objects by using onGoogleApiLoaded, in this case you will need to set yesIWantToUseGoogleMapApiInternals to true
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
      >
        {markers}
      </GoogleMapReact>
    </div>
  );
};

Map.propTypes = {
  address: PropTypes.string.isRequired,
  //{lat: 0,lng: 0}
  location: PropTypes.object,
  icon: PropTypes.string.isRequired
};

export default Map;
