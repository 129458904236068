import React from 'react';
// import PropTypes from 'prop-types';
import { Translate } from 'components/Localize';
// import { Kind } from 'components/Format';
import styles from './CallBackSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { connect } from 'react-redux';
import {
  faChevronRight,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
// import operator1 from 'styles/img/operator1.png';
import coma from 'styles/img/coma.png';
import Section from 'components/Section';
// import AsyncPopup from './AsyncPopup';

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <FontAwesomeIcon
      className="slick-button-prev"
      onClick={onClick}
      icon={faChevronLeft}
    />
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <FontAwesomeIcon
      className="slick-button-next"
      onClick={onClick}
      icon={faChevronRight}
    />
  );
}

class CallBackSection extends React.Component {
  state = {
    nav1: null,
    nav2: null
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const settingsSliderChange = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      adaptiveHeight: true,
      slidesToScroll: 1,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      asNavFor: this.state.nav2
    };
    const settingsSliderFix = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 0,
      slidesToShow: 1,
      adaptiveHeight: true,
      slidesToScroll: 1,
      asNavFor: this.state.nav1
    };
    const { className, callLinks } = this.props;
    return (
      <div className={`${styles['call-body']} ${styles[className]}`}>
        <Section>
          <div className={styles['call-body__inner']}>
            <div className={styles['call-body__slider']}>
              <Slider
                {...settingsSliderChange}
                ref={slider => (this.slider1 = slider)}
              >
                {callLinks.map((operator, idx) => (
                  <div key={idx}>
                    <div className={styles['call-body__item']}>
                      <div className={styles['call-body__avatar']}>
                        <img src={operator.cover_image} alt="operator" />
                        <p className={styles['call-body__info']}>
                          {operator.assistant_info}
                        </p>
                        <h3 className={styles['call-body__title']}>
                          <img src={coma} alt="coma" />
                          {operator.title}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className={styles['call-body__container']}>
              <Slider
                {...settingsSliderFix}
                ref={slider => (this.slider2 = slider)}
              >
                {callLinks.map((buttons, idx) => (
                  <div key={idx}>
                    <div className={styles['call-body__form']}>
                      <div className={styles['call-body__box']}>
                        <div className={styles['call-body__text']}>
                          <Translate id="we-may-we-contact-you" />
                        </div>
                        {/*<AsyncPopup />*/}

                        <a
                          href={buttons.callme_link}
                          type="button"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`${styles['call-body__button']} ${
                            styles['call-body__button--green']
                          }`}
                        >
                          <div className={styles['call-body__phone']}>
                            <FontAwesomeIcon icon={faWhatsapp} />
                          </div>
                          <Translate id="yes-call-me" />
                        </a>
                        <a
                          href={buttons.whatsapp_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          type="button"
                          className={`${styles['call-body__button']} ${
                            styles['call-body__button--red']
                          }`}
                        >
                          <FontAwesomeIcon
                            className={styles['call-body__svg']}
                            icon={faWhatsapp}
                          />
                          <Translate id="write-me-whatsapp" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

// CallBackForm.propTypes = {
//   vacancy: PropTypes.object.isRequired
// };
const mapDispatchToProps = ({ assistants }) => {
  return {
    callLinks: (assistants.data && assistants.data.application_assistants) || []
  };
};
export default connect(mapDispatchToProps)(CallBackSection);
