import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse, stringify } from 'qs';
import AssessTokenStorage from 'lib/accessTokenStorage';

const TokenReceiver = ({ location, history, children }) => {
  const [done, setDone] = useState(false);
  useEffect(
    () => {
      const query = parse(location.search.slice(1));
      if ('auth_token' in query) {
        AssessTokenStorage.set(query.auth_token);
        history.replace({
          pathname: location.pathname,
          search: stringify(Object.assign({}, query, { auth_token: undefined }))
        });
      } else {
        setDone(true);
      }
    },
    [location.search]
  );

  if (done) {
    return children;
  }
  // show loading indicator
  return null;
};

TokenReceiver.propTypes = {
  children: PropTypes.node.isRequired
};

export default withRouter(TokenReceiver);
