import React from 'react';
import PropTypes from 'prop-types';
import Standard from './Standard';
import TopJob from './TopJob';

const Header = ({ vacancy }) => {
  if (vacancy.top_job) {
    return <TopJob vacancy={vacancy} />;
  }
  // Sergey asked to disable recruiter view because mobile app needs to be recruiter_video always returned.
  /*if (vacancy.recruiter_video) {
    return <RecruiterJob vacancy={vacancy} />;
  }*/

  return <Standard vacancy={vacancy} />;
};

Header.propTypes = {
  vacancy: PropTypes.object.isRequired
};

export default Header;
