import * as types from 'actions/types';
import { createReducer, successType } from 'lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_KIND_LIST):
      action.response.sort(
        (a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0)
      );
      return [...action.response];
    default:
      return state;
  }
};

export default createReducer(types.GET_KIND_LIST, dataReducer);
