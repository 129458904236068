import * as types from './types';
import vacancyApi from 'api/VacancyApi';
import { getItems } from 'reducers/vacancyReducer';
import { createAction } from 'lib/callAPI';

export function searchByCriteria(criteria, pageIndex = 0) {
  return createAction({
    type: types.SEARCH_VACANCY_LIST,
    callAPI: () => vacancyApi.search(criteria, pageIndex),
    shouldCallAPI: state => !getItems(state.vacancy.data, criteria, pageIndex)
  });
}

export function getById(id) {
  return createAction({
    type: types.GET_VACANCY,
    callAPI: () => vacancyApi.get(id),
    shouldCallAPI: state => !state.vacancy.data.byId[id]
  });
}

export function getRelated(companyId, vacancyId, pageIndex = 0) {
  return createAction({
    type: types.GET_RELATED_VACANCY,
    callAPI: () => vacancyApi.getRelated(companyId, vacancyId, pageIndex),
    id: vacancyId,
    shouldCallAPI: state =>
      !state.relatedVacancies.data.byId[vacancyId] ||
      !state.relatedVacancies.data.byId[vacancyId].byPage[pageIndex]
  });
}

export function getNearest(vacancyId) {
  return createAction({
    type: types.GET_NEAREST_VACANCIES,
    callAPI: () => vacancyApi.getNearest(vacancyId),
    shouldCallAPI: state => !state.nearestVacancies.data.byId[vacancyId]
  });
}
