import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import './Pagination.css';

const Pagination = ({ pageCount, pageIndex, onChange }) => {
  const handlePageChange = ({ selected }) => {
    onChange(selected);
  };
  return (
    <ReactPaginate
      previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
      nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
      breakLabel="..."
      breakClassName="page-link"
      forcePage={pageIndex}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      onPageChange={handlePageChange}
      containerClassName="pagination"
      pageClassName="page-item"
      previousClassName="page-item"
      nextClassName="page-item"
      pageLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      activeClassName="active"
      disabledClassName="disabled"
    />
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func
};

export default Pagination;
