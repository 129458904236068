import ServerApi from './ServerApi';
import { getDefaultLanguage } from 'lib/languages';

const PAGE_SIZE = process.env.REACT_APP_JOB_LIST_PAGE_SIZE;
const RELATED_PAGE_SIZE = 4;

class VacancyApi extends ServerApi {
  search(criteria, pageIndex) {
    const { text, sort, kind, tag, companyId, city, date, partner } = criteria;
    const offset = pageIndex * PAGE_SIZE;
    const kinds = kind.length > 0 ? `&kind=${kind.join(',')}` : '';
    const tags = tag.map(item => `&tag=${item}`).join('');
    const company = companyId ? `&company=${companyId}` : '';
    const cityOp = city ? `&city=${encodeURIComponent(city)}` : '';
    const dateOp = date ? `&vacancy_start__gte=${date}` : '';
    const partnerOp = partner ? `&partner=${partner}` : '';
    return super
      .get(
        `/v1.3/recruiting/vacancy/?language=${getDefaultLanguage()}&search=${encodeURIComponent(
          text
        )}&ordering=${sort}&limit=${PAGE_SIZE}&offset=${offset}${kinds}${tags}${company}${cityOp}${dateOp}${partnerOp}`,
        true
      )
      .then(({ data, headers }) => {
        const totalCount = headers.get('element-count');
        const pageCount = Math.ceil(totalCount / PAGE_SIZE);
        return { data, pageCount, pageIndex, criteria, totalCount };
      });
  }

  getRelated(companyId, vacancyId, pageIndex) {
    const offset = pageIndex * RELATED_PAGE_SIZE;

    return super
      .get(
        `/v1.3/recruiting/vacancy/?language=${getDefaultLanguage()}&limit=${RELATED_PAGE_SIZE}&offset=${offset}&company=${companyId}&exclude_id=${vacancyId}`,
        true
      )
      .then(({ data, headers }) => {
        const totalCount = headers.get('element-count');
        const pageCount = Math.ceil(totalCount / RELATED_PAGE_SIZE);
        return {
          data,
          pageCount,
          pageIndex,
          vacancyId
        };
      });
  }

  getNearest(vacancyId) {
    return super
      .get(
        `/v1.3/recruiting/vacancy/${vacancyId}/nearest/?language=${getDefaultLanguage()}&limit=100&same_company=true&proximity=500`,
        true
      )
      .then(({ data }) => ({ data, vacancyId }));
  }

  get(pk) {
    return super.get(
      `/v1.3/recruiting/vacancy/${pk}/?language=${getDefaultLanguage()}`
    );
  }

  getSortingFields() {
    return [
      /*{ name: '-created', translateKey: 'date' },*/
      { name: 'title', translateKey: 'title' },
      /*{ name: 'vacancy_start', translateKey: 'started' },*/
      { name: 'kind', translateKey: 'kind' }
      /*{ name: 'company', translateKey: 'company' }*/
    ];
  }
}

export default new VacancyApi();
