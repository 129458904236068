import TagManager from 'react-gtm-module';

const googleTagManagerCode = process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE;

export const init = () => {
  //initialize only of env variable exist.
  if (googleTagManagerCode) {
    TagManager.initialize({ gtmId: googleTagManagerCode });
  }
};

export const trackVirtualPageView = title => {
  //track only of env variable exist.
  if (googleTagManagerCode) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'VirtualPageview',
        virtualPageURL: window.location.href,
        virtualPageTitle: title
      }
    });
  }
};
