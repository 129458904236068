import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Results from './Results';
import Details from './Vacancy/Details';
import { withPageTrack } from 'components/Tracking';

const DetailsWithTrack = withPageTrack(Details, 'Vacancy Job Details');
const ResultsWithTrack = withPageTrack(Results, 'Vacancy List');

export default () => (
  <Route>
    {({ match }) => (
      <Switch>
        <Route
          path={`${match.url}/s/vacancy/:id`}
          //use render in order not to create component (rerender items, flickering)
          render={props => <DetailsWithTrack {...props} />}
        />
        <Route render={props => <ResultsWithTrack {...props} />} />
      </Switch>
    )}
  </Route>
);
