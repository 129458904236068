import React from 'react';
import Header from 'app/Header';
import BlurEffect from 'components/BlurEffect';
import cx from 'classnames';
import Footer from './Footer';
import './Layout.css';

const Layout = ({ children }) => (
  <div className="layout">
    <Header />
    <BlurEffect>
      {({ className }) => (
        <div className={cx('main', className)}>{children}</div>
      )}
    </BlurEffect>
    <Footer />
  </div>
);
export default Layout;
