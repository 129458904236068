import React from 'react';
import { Marker } from 'components/Map';
import MarketTooltip from './MarketTooltip';
import MarkerMultipleTooltip from './MarkerMultipleTooltip';

const groupByPoint = list => {
  return list.reduce(
    (result, item) => [
      ...result.filter(
        t => t.latitude !== item.latitude && t.longitude !== item.longitude
      ),
      {
        latitude: item.latitude,
        longitude: item.longitude,
        items: [
          ...(result.find(
            t => t.latitude === item.latitude && t.longitude === item.longitude
          )
            ? result.find(
                t =>
                  t.latitude === item.latitude && t.longitude === item.longitude
              ).items
            : []),
          item
        ]
      }
    ],
    []
  );
};

const Nearest = (nearestVacancies, resolve) => {
  return groupByPoint(nearestVacancies).map(
    ({ latitude, longitude, items }, index) => {
      if (items.length === 1) {
        const vacancy = items[0];
        return (
          <Marker
            key={`marker_${index}`}
            icon={vacancy.company_logo}
            tooltip={
              <MarketTooltip
                logo={vacancy.companylogo}
                title={vacancy.vacancy_title}
                href={resolve(`/s/vacancy/${vacancy.pk}`)}
                company={vacancy.company_name}
                kind={vacancy.kind}
              />
            }
            lat={vacancy.latitude}
            lng={vacancy.longitude}
          />
        );
      } else {
        return (
          <Marker
            key={`marker_${index}`}
            tooltip={<MarkerMultipleTooltip items={items} resolve={resolve} />}
            label={`${items.length} jobs`}
            lat={latitude}
            lng={longitude}
            multi
          />
        );
      }
    }
  );
};

export default Nearest;
