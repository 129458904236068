import React from 'react';
import Tooltip from 'components/Tooltip';
import { Translate } from 'components/Localize';
import { connect } from 'react-redux';
import { logout } from 'actions/authActions';
import Branding from 'components/Branding';
import asyncComponent from 'components/AsyncComponent';
import { getLoginUrl, getSignUpUrl } from 'lib/landing';
import { getDefaultLanguage } from 'lib/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAssistantList } from 'actions/assistantAction';
import {
  faCalendarCheck,
  faChevronDown,
  faFemale,
  faMale,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import './Profile.css';
import { Portal } from 'react-portal';
import LanguageSwitcher from 'app/Layout/LanguageSwitcher';

const AsyncMyApplications = asyncComponent(() =>
  import(/* webpackChunkName: "my-applications" */ 'app/MyApplications')
);
const AsyncMyProfile = asyncComponent(() =>
  import(/* webpackChunkName: "my-profile" */ 'app/Profile')
);

class Profile extends React.Component {
  state = {
    open: false,
    myApplicationsOpen: false,
    myProfileOpen: false,
    isOpenMenu: false
  };
  myRef = React.createRef();

  handleOpenMenu = () => {
    if (!this.state.isOpenMenu) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState({
      isOpenMenu: !this.state.isOpenMenu
    });
  };

  handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (this.myRef.current.contains(e.target)) {
      return;
    }
    this.handleOpenMenu();
  };

  handleOpen = () => {
    AsyncMyApplications.preload();
    AsyncMyProfile.preload();
    this.setState({ open: true });
  };

  handleHide = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    this.props.logout();
    this.setState({ open: false });

    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.signOut();
        auth2.disconnect();
      }
    }
    if (window.FB) {
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          window.FB.logout();
        }
      });
    }
  };

  handleMyApplicationsClick = () => {
    this.setState({
      open: false,
      myApplicationsOpen: true
    });
  };
  handleMyProfileClick = () => {
    this.setState({
      open: false,
      myProfileOpen: true
    });
  };
  handleMyApplicationsClose = () => {
    this.setState({
      myApplicationsOpen: false
    });
  };

  componentDidMount() {
    this.props.getAssistantList();
  }

  handleMyProfileClose = () => {
    this.setState({
      myProfileOpen: false
    });
  };

  render() {
    const {
      isAuthenticated,
      firstName,
      lastName,
      gender,
      forCompanies
    } = this.props;
    const { open, myApplicationsOpen, myProfileOpen } = this.state;
    const companyLink = forCompanies[0];

    return (
      <Branding>
        {resolve => (
          <React.Fragment>
            {!isAuthenticated && (
              <div
                className={`change-menu ${this.state.isOpenMenu ? 'show' : ''}`}
              >
                <div
                  className="change-menu__buttons"
                  onClick={this.handleOpenMenu}
                >
                  <div className="change-menu__button">
                    <span />
                    <span />
                    <span />
                  </div>
                  <div className="change-menu__arrow">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
                <div ref={this.myRef} className="change-menu__open">
                  <div className="change-menu__box">
                    {resolve('header.show_menu', true) && (
                      <div className="header-link header-link--space change-menu__link">
                        <a href={companyLink && companyLink.absolute_url_path}>
                          {companyLink && companyLink.title}
                        </a>
                      </div>
                    )}
                    <a
                      href={`${getLoginUrl(
                        getDefaultLanguage()
                      )}?next=/redirect-to/?link=${window.location.href}`}
                      className="header-link header-link--space change-menu__link"
                    >
                      <span>
                        <Translate id="login-title" />
                      </span>
                    </a>
                    <a
                      href={`${getSignUpUrl(
                        getDefaultLanguage()
                      )}?next=/redirect-to/?link=${window.location.href}`}
                      className="button button-transparent header-link--space "
                    >
                      <span>
                        <Translate id="sign" />
                      </span>
                    </a>
                    <div className="header-link header-link--space header-link--lang">
                      <LanguageSwitcher />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {myApplicationsOpen && (
              <Portal>
                <AsyncMyApplications
                  open={myApplicationsOpen}
                  onClose={this.handleMyApplicationsClose}
                />
              </Portal>
            )}
            {myProfileOpen && (
              <Portal>
                <AsyncMyProfile
                  open={myProfileOpen}
                  onClose={this.handleMyProfileClose}
                />
              </Portal>
            )}
            {isAuthenticated && (
              <>
                <Tooltip
                  on="manual"
                  placement="bottom-end"
                  open={open}
                  onOpen={this.handleOpen}
                  onHide={this.handleHide}
                  theme="light"
                  message={
                    <ul className="profile-menu">
                      <li
                        className="profile-item-btn"
                        onClick={this.handleMyProfileClick}
                      >
                        <FontAwesomeIcon
                          className="icon"
                          icon={gender === 'F' ? faFemale : faMale}
                        />
                        {firstName} {lastName}
                      </li>
                      <li
                        className="profile-item-btn"
                        onClick={this.handleMyApplicationsClick}
                      >
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          className="icon"
                        />
                        <Translate id="my-applications" />
                      </li>
                      <li
                        className="profile-item-btn"
                        onClick={this.handleLogout}
                      >
                        <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
                        <Translate id="logout" />
                      </li>
                    </ul>
                  }
                >
                  <div className="profile-label">{firstName}</div>
                </Tooltip>
                <LanguageSwitcher />
              </>
            )}
          </React.Fragment>
        )}
      </Branding>
    );
  }
}

const mapStateToProps = ({ currentUser, assistants }) => ({
  isAuthenticated: !!currentUser,
  firstName: currentUser && currentUser.user && currentUser.user.first_name,
  lastName: currentUser && currentUser.user && currentUser.user.last_name,
  gender: currentUser && currentUser.gender,
  forCompanies:
    (assistants.data && assistants.data.menu && assistants.data.menu.header) ||
    []
});

export default connect(mapStateToProps, { logout, getAssistantList })(Profile);
