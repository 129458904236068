import * as types from 'actions/types';
import { createReducer, successType } from 'lib/callAPI';
import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case successType(types.GET_RELATED_VACANCY):
      return {
        ...state,
        [action.response.vacancyId]: {
          pageCount: pageCount(
            state[action.response.vacancyId] &&
              state[action.response.vacancyId].pageCount,
            action
          ),
          byPage: byPage(
            state[action.response.vacancyId] &&
              state[action.response.vacancyId].byPage,
            action
          )
        }
      };
    default:
      return state;
  }
};

const pageCount = (state = '', action) => {
  switch (action.type) {
    case successType(types.GET_RELATED_VACANCY):
      return action.response.pageCount;
    default:
      return state;
  }
};

const byPage = (state = {}, action) => {
  switch (action.type) {
    case successType(types.GET_RELATED_VACANCY):
      return {
        ...state,
        [action.response.pageIndex]: [...action.response.data]
      };
    default:
      return state;
  }
};

const dataReducer = combineReducers({
  byId
});

export default createReducer(types.GET_RELATED_VACANCY, dataReducer);
