import React from 'react';
import { parse, stringify } from 'qs';
import { CompanyResolver } from 'app/CompanyProvider';
import { Route } from 'react-router-dom';

export default Composed => {
  return props => {
    return (
      <Route>
        {router => (
          <CompanyResolver>
            {({ resolve }) => {
              const extend = {
                pushUriStateWithoutPage(state, pathname) {
                  extend.pushUriState({ ...state, page: undefined }, pathname);
                },
                pushUriState(state, pathname = resolve('/s')) {
                  const newState = Object.assign(
                    {},
                    parse(router.location.search.slice(1)),
                    state
                  );
                  router.history.push({
                    pathname,
                    search: stringify(newState, { indices: false })
                  });
                },
                getUriState(location = router.location) {
                  const {
                    search = '',
                    city = '',
                    date = '',
                    page = 0,
                    sort = '-created',
                    kind = [],
                    tag = []
                  } = parse(location.search.slice(1));
                  return {
                    search,
                    city,
                    date,
                    page: parseInt(page, 10),
                    sort,
                    kind: Array.isArray(kind) ? kind : [kind],
                    tag: Array.isArray(tag) ? tag : [tag]
                  };
                }
              };
              return <Composed {...props} {...extend} />;
            }}
          </CompanyResolver>
        )}
      </Route>
    );
  };
};
