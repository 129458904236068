import React from 'react';
import 'placeholder-loading/src/scss/placeholder-loading.scss';
import './Placeholder.css';

const Placeholder = ({ count = 1, type }) => {
  let items = [];
  if (count) {
    const arr = new Array(+count).fill('');
    items = arr.map((i, idx) => {
      if (type === 'details') {
        return <Details key={idx} />;
      }
      if (type === 'list') {
        return <List key={idx} />;
      }
      return null;
    });
  }
  return items;
};
function Details() {
  return (
    <div>
      <div className="ph-item">
        <div className="ph-col-2">
          <div className="ph-avatar" />
        </div>
        <div className="ph-col-6 ">
          <div className="ph-row">
            <div className="ph-col-8" />
            <div className="ph-col-12 empty big" />
            <div className="ph-col-10 big" />
            <div className="ph-col-12 empty big" />
            <div className="ph-col-6" />
          </div>
        </div>
        <div className="ph-col-2">
          <div className="ph-row">
            <div className="ph-col-12" />
            <div className="ph-col-12 small empty" />
            <div className="ph-col-12" />
            <div className="ph-col-12 small empty" />
            <div className="ph-col-12" />
          </div>
        </div>
        <div className="ph-col-2 media-phone-button">
          <div className="ph-row">
            <div className="ph-col-12 big" />
            <div className="ph-col-12 big" />
          </div>
        </div>
      </div>
      <div className="ph-item media-photo-group">
        <div className="ph-col-4">
          <div className="ph-picture" />
          <div className="ph-picture" />
          <div className="ph-col-12 small empty" />
        </div>
        <div className="ph-col-4">
          <div className="ph-picture" />
          <div className="ph-picture" />
          <div className="ph-col-12 small empty" />
        </div>
        <div className="ph-col-4">
          <div className="ph-picture" />
          <div className="ph-picture" />
          <div className="ph-col-12 small empty" />
        </div>
        <div className="ph-col-4">
          <div className="ph-picture" />
          <div className="ph-picture" />
          <div className="ph-col-12 small empty" />
        </div>
        <div className="ph-col-4">
          <div className="ph-picture" />
          <div className="ph-picture" />
          <div className="ph-col-12 small empty" />
        </div>
        <div className="ph-col-4">
          <div className="ph-picture" />
          <div className="ph-picture" />
          <div className="ph-col-12 small empty" />
        </div>
      </div>
    </div>
  );
}
function List() {
  return (
    <div className="ph-item">
      <div className="ph-col-2">
        <div className="ph-avatar" />
      </div>
      <div className="ph-col-6">
        <div className="ph-row">
          <div className="ph-col-8" />
          <div className="ph-col-12 empty big" />
          <div className="ph-col-10 big" />
          <div className="ph-col-12 empty big" />
          <div className="ph-col-6" />
        </div>
      </div>
      <div className="ph-col-2">
        <div className="ph-row">
          <div className="ph-col-12" />
          <div className="ph-col-12 small empty" />
          <div className="ph-col-12" />
          <div className="ph-col-12 small empty" />
          <div className="ph-col-12" />
        </div>
      </div>
      <div className="ph-col-2">
        <div className="ph-row">
          <div className="ph-col-12 big" />
          <div className="ph-col-12 big" />
        </div>
      </div>
    </div>
  );
}

export default Placeholder;
