import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'components/Carousel';
import Modal from 'components/Modal';
import asyncComponent from 'components/AsyncComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import style from './Videos.module.css';
import photoStyle from '../Photos/Photos.module.css';
import Translate from '../../../../components/Localize/Translate';

const AsyncPlayer = asyncComponent(() =>
  import(/* webpackChunkName: "player" */ 'components/Player')
);

class Videos extends React.Component {
  state = {
    currentIndex: 0,
    modalOpen: false
  };

  handleImageClickFor = index => () => {
    this.setState(
      {
        modalOpen: true,
        currentIndex: index
      },
      () => {
        //in order to set focus to slider when the modal is open to be able use arrow keys navigation.
        this.carouselModal.innerSlider.list.setAttribute('tabindex', 0);
        this.carouselModal.innerSlider.list.focus();
      }
    );
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });
  };

  render() {
    const { videos, companyLogo, singlePhoto } = this.props;
    const { modalOpen, currentIndex } = this.state;

    if (videos.length === 0) {
      return null;
    }

    const firstVideo = videos[0];
    return (
      <React.Fragment>
        <Modal
          maxWidth="700px"
          open={modalOpen}
          onClose={this.handleModalClose}
        >
          <div className={photoStyle['carousel-container-modal']}>
            <Carousel
              initialSlide={currentIndex}
              ref={c => (this.carouselModal = c)}
              dots
            >
              {videos.map((video, index) => (
                <div key={`video_${index}`}>
                  <AsyncPlayer
                    src={video.url}
                    type={video.type}
                    autoplay={false}
                    previewSrc={video.preview}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </Modal>

        <div
          className={cx(style['videos'], {
            [style['single-photo']]: singlePhoto
          })}
        >
          <div className={style['video-preview']}>
            <AsyncPlayer
              src={firstVideo.url}
              type={firstVideo.type}
              previewSrc={firstVideo.preview}
              autoplay={false}
            />
            <div className={style['video-details']}>
              <div>
                <img
                  src={companyLogo}
                  alt="logo"
                  className={style['video-logo']}
                />
                {firstVideo.title && (
                  <div className={style['video-title']}>{firstVideo.title}</div>
                )}
              </div>
              {firstVideo.duration && (
                <span className={style['video-duration']}>
                  {firstVideo.duration}
                </span>
              )}
            </div>
            {videos.length > 1 && (
              <div
                className={style['more-videos']}
                onClick={this.handleImageClickFor(1)}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={style['more-videos-icon']}
                />
                <Translate id="more-videos" />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Videos.propTypes = {
  videos: PropTypes.array.isRequired,
  companyLogo: PropTypes.string.isRequired,
  singlePhoto: PropTypes.bool.isRequired
};

export default Videos;
