import React from 'react';
import { Translate } from 'components/Localize';
import Whatsapp from './Whatsapp';
import Telegram from './Telegram';
import Sms from './Sms';
import Discord from './Discord';
import Email from './Email';
import styles from '../LeftSideFilter.module.css';

const MultiChannelReminder = () => {
  return (
    <div>
      <div className={styles['multichannel-header']}>
        <Translate id="lets-have-chat" />
      </div>
      <Whatsapp />
      <Telegram />
      <Discord />
      <Sms />
      <Email />
    </div>
  );
};

export default MultiChannelReminder;
