import * as types from 'actions/types';
import { createReducer, successType } from 'lib/callAPI';
import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case successType(types.GET_NEAREST_VACANCIES):
      return {
        ...state,
        [action.response.vacancyId]: action.response.data
      };
    default:
      return state;
  }
};

const dataReducer = combineReducers({
  byId
});

export default createReducer(types.GET_NEAREST_VACANCIES, dataReducer);
