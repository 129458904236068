import React from 'react';
import { Translate } from 'components/Localize/index';
import Section from 'components/Section';
import PropTypes from 'prop-types';
import LinkButton from 'components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './Error.css';

const Error = ({ children, general = false, retry }) => {
  if (!(children || general)) {
    return null;
  }
  return (
    <Section>
      <div className="alert-error">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="alert-error-icon"
        />
        {general ? (
          <span>
            <Translate id="request-failed" />{' '}
            {retry && (
              <LinkButton className="primary" onClick={retry}>
                <Translate id="please-try-again" />
              </LinkButton>
            )}
          </span>
        ) : (
          children
        )}
      </div>
    </Section>
  );
};

Error.propTypes = {
  general: PropTypes.bool,
  retry: PropTypes.func
};

export default Error;
