import english from 'styles/images/english.png';
import germany from 'styles/images/germany.png';
import poland from 'styles/images/poland.png';

import enLocale from 'date-fns/locale/en';
import deLocale from 'date-fns/locale/de';
import plLocale from 'date-fns/locale/pl';

export const locales = {
  en: enLocale,
  de: deLocale,
  pl: plLocale
};

//The order matters! The order MUST follow the order of server return translation.
export const languages = [
  { name: 'English', code: 'en', flag: english },
  { name: 'Deutsch', code: 'de', flag: germany },
  { name: 'Polski', code: 'pl', flag: poland }
];

const getDefaultBrowserLanguage = () => {
  let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang =
    lang ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage;
  if (lang.indexOf('-') !== -1) lang = lang.split('-')[0];

  if (lang.indexOf('_') !== -1) lang = lang.split('_')[0];
  return lang;
};

export const getDefaultLanguage = () => {
  const browserLanguage = getDefaultBrowserLanguage();
  return (
    localStorage.getItem('languageCode') ||
    (languages.map(lang => lang.code).includes(browserLanguage)
      ? browserLanguage
      : 'de')
  );
};

export const setDefaultLanguage = code => {
  localStorage.setItem('languageCode', code);
};
