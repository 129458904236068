import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from 'components/Modal';
import Carousel from 'components/Carousel';
import { Mobile, SmallMobile } from 'components/MediaQuery';
import style from './Photos.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Translate from 'components/Localize/Translate';
import BodyImage from 'styles/images/2000px-Aspect-ratio-16x9.svg.png';

class Photos extends React.Component {
  state = {
    modalOpen: false,
    currentIndex: 0
  };

  handleImageClickFor = index => () => {
    this.setState(
      {
        modalOpen: true,
        currentIndex: index
      },
      () => {
        //in order to set focus to slider when the modal is open to be able use arrow keys navigation.
        if (
          this.carouselModal &&
          this.carouselModal.innerSlider &&
          this.carouselModal.innerSlider.list
        ) {
          this.carouselModal.innerSlider.list.setAttribute('tabindex', 0);
          this.carouselModal.innerSlider.list.focus();
        }
      }
    );
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });
  };

  render() {
    const { photos, hasVideos } = this.props;
    const { modalOpen, currentIndex } = this.state;

    if (photos.length === 0) {
      //in order if video is alone
      return <div className={style['clear-both']} />;
      // return null
    }

    const singlePhoto = photos.length === 1;
    return (
      <React.Fragment>
        <Modal
          maxWidth="700px"
          open={modalOpen}
          onClose={this.handleModalClose}
        >
          <div className={style['carousel-container-modal']}>
            <Carousel
              initialSlide={currentIndex}
              ref={c => (this.carouselModal = c)}
              dots
            >
              {photos.map(photo => (
                <div key={photo.pk}>
                  <img
                    className={style['image-full']}
                    src={photo.image}
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </Modal>
        <Mobile>
          {isMobile => (
            <SmallMobile>
              {isSmallMobile => {
                const showMoreIndex = isSmallMobile ? 1 : 2;
                const smallPhotosStartIndex =
                  hasVideos && (isSmallMobile || isMobile) ? 0 : 1;
                const smallPhotos = photos.slice(
                  smallPhotosStartIndex,
                  smallPhotosStartIndex + 3
                );
                return (
                  <div
                    className={cx(
                      style['photos-grid'],
                      { [style['has-videos']]: hasVideos },
                      { [style['single-photo']]: singlePhoto },
                      { [style['full-width']]: smallPhotos.length === 0 }
                    )}
                  >
                    <div
                      className={style['photos-item-image']}
                      style={{
                        backgroundImage: 'url(' + photos[0].image + ')'
                      }}
                      onClick={this.handleImageClickFor(0)}
                    >
                      <img src={BodyImage} alt="img" />
                    </div>
                    {smallPhotos.length > 0 && (
                      <div
                        className={cx(style['photos-grid-second-coll'], {
                          [style['has-videos']]: hasVideos
                        })}
                      >
                        {smallPhotos.map((photo, index) => {
                          const renderMore = children => {
                            if (
                              index === showMoreIndex &&
                              photos.length >
                                showMoreIndex + smallPhotosStartIndex + 1
                            ) {
                              return (
                                <div
                                  key={photo.pk}
                                  className={cx(
                                    style['photos-item-more'],
                                    style['photos-item-image']
                                  )}
                                >
                                  <div
                                    className={style['photos-item-more-label']}
                                    onClick={this.handleImageClickFor(
                                      index + smallPhotosStartIndex
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      size="2x"
                                      className={style['photos-item-more-icon']}
                                    />
                                    <Translate id="more-photos" />
                                  </div>
                                  {children}
                                </div>
                              );
                            }
                            return children;
                          };
                          return renderMore(
                            <div
                              key={photo.pk}
                              style={{
                                backgroundImage: 'url(' + photo.image + ')'
                              }}
                              className={style['photos-item-image']}
                              onClick={this.handleImageClickFor(
                                index + smallPhotosStartIndex
                              )}
                            >
                              <img src={BodyImage} alt="img" />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              }}
            </SmallMobile>
          )}
        </Mobile>
      </React.Fragment>
    );
  }
}

Photos.propTypes = {
  photos: PropTypes.array.isRequired,
  hasVideos: PropTypes.bool.isRequired
};

export default Photos;
