import React from 'react';
import PropTypes from 'prop-types';
import DropDown from 'components/DropDown';
import Loader from 'components/Loader';
import { Error } from 'components/Alert';
import Checkbox from 'components/Checkbox';
import { getTagList } from 'actions/tagActions';
import { connect } from 'react-redux';
import style from './TagDropDown.module.css';
import { Translate } from 'components/Localize';

class TagDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedTags: props.value
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.props.getTagList(this.props.companyId, this.props.partner).then(() => {
      //get data from store, because action could return empty promise
      //if something is defined in url, don't change selection
      if (this.state.checkedTags.length === 0) {
        this.setState({
          checkedTags: []
        });
      }
    });
  };

  componentDidUpdate(prevProps) {
    //compare array
    if (this.props.value.join('') !== prevProps.value.join('')) {
      this.setState({
        checkedTags: this.props.value
      });
    }
  }

  handleChangeFor = name => e => {
    const { checkedTags } = this.state;
    let newState;
    if (e.target.checked) {
      newState = [...checkedTags, name];
    } else {
      newState = checkedTags.filter(kind => kind !== name);
    }
    this.setState({
      checkedTags: newState
    });
  };

  handleClear = () => {
    this.setState({
      checkedTags: []
    });
  };

  handleApply = () => {
    const { checkedTags } = this.state;
    const { onChange, name, data } = this.props;
    const allChecked = checkedTags.length === data.length;
    onChange({
      target: { name, value: allChecked ? [] : checkedTags },
      persist: () => {}
    });
  };

  renderContent() {
    const { loading, data, error } = this.props;
    const { checkedTags } = this.state;
    if (error) {
      return <Error general retry={this.fetchData} />;
    }
    if (loading) {
      return <Loader />;
    }
    return (
      <div className={style['tag-dropdown-content']}>
        {data.map(tag => (
          <Checkbox
            key={`tag_checkbox${tag.slug}`}
            value={checkedTags.includes(tag.slug)}
            onChange={this.handleChangeFor(tag.slug)}
          >
            {tag.name}
          </Checkbox>
        ))}
      </div>
    );
  }

  getHeaderText() {
    const { checkedTags } = this.state;
    const { data } = this.props;
    const allChecked = checkedTags.length === data.length;
    const count = checkedTags.length;
    return (
      <span>
        <Translate id="industry" />
        {count > 0 && !allChecked && ` · ${count}`}
      </span>
    );
  }

  render() {
    const { checkedTags } = this.state;
    const { className, data } = this.props;
    const someChecked = checkedTags.length > 0;

    if (data.length === 0) {
      //hide filter in case these are no industries.
      return null;
    }
    return (
      <DropDown
        className={className}
        containerClassName={style['tag-dropdown-container']}
        header={this.getHeaderText()}
        onClear={this.handleClear}
        onApply={this.handleApply}
        showClear={someChecked}
        active={checkedTags.length > 0}
      >
        {this.renderContent()}
      </DropDown>
    );
  }
}

TagDropDown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = ({ company, tag: { data, error, loading } }) => ({
  companyId: company.data && company.data.company && company.data.company.pk,
  partner: company.data && company.data.partner,
  loading: !data || loading,
  error: error,
  data: data || []
});

export default connect(
  mapStateToProps,
  { getTagList }
)(TagDropDown);
