import React from 'react';
import { Field } from 'formik';
import GroupInput from './GroupInput';
import GroupInputWithWhiteError from './GroupInputWithWhiteError';
import TextInput from '../TextInput';

const InputField = ({ component = TextInput, ...props }) => {
  return (
    <Field
      {...props}
      component={props.whiteError ? GroupInputWithWhiteError : GroupInput}
      innerComponent={component}
    />
  );
};

export default InputField;
