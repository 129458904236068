import * as types from 'actions/types';
import { createReducer, successType } from 'lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_TAG_LIST):
      action.response.sort((a, b) => {
        if (a.slug === 'other') {
          return 1;
        }
        if (b.slug === 'other') {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
      return [...action.response];
    default:
      return state;
  }
};

export default createReducer(types.GET_TAG_LIST, dataReducer);
