import ServerApi from './ServerApi';
import { getDefaultLanguage } from 'lib/languages';

class ApplicationApi extends ServerApi {
  getAll() {
    return super.get(`/recruiting/application/`);
  }

  getStatuses() {
    return super.get(
      `/recruiting/application-status/?language=${getDefaultLanguage()}`
    );
  }
}

export default new ApplicationApi();
