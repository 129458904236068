import React from 'react';
import KindDropDown from './KindDropDown';
import withSearchState from 'app/withSearchState';

const Kind = ({ getUriState, pushUriStateWithoutPage }) => {
  const { kind } = getUriState();
  const handleChange = e => {
    pushUriStateWithoutPage({ kind: e.target.value });
  };
  return <KindDropDown value={kind} onChange={handleChange} />;
};

export default withSearchState(Kind);
