import React from 'react';
import cx from 'classnames';
import './Spin.css';

const Spin = ({ light, small }) => {
  return (
    <div className={cx({ light, small }, 'loading-indicator-container')}>
      <div className={cx({ light, small }, 'loading-indicator')} />
    </div>
  );
};

export default Spin;
